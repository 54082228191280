.btn {
    font-family: $buttonFontStack;
    font-weight: normal;

    @include mq($max, $small) {
        font-weight: normal;
    }

    $btn-color-primary: #638fbd;
    $btn-color-primary-hover: #227fe2;

    $btn-color-primary-border: #3d79b9;
    $btn-color-primary-border-hover: #638fbd;


    $btn-color-info: #95afc4;
    $btn-color-info-hover: #70a2d8;

    $btn-color-info-border: #768da5;
    $btn-color-info-border-hover: #6f7d8b;


    &.btn-primary {
        color: #fff;
        background-color: $btn-color-primary;
        border-color: $btn-color-primary-border;

        &:hover {
            background-color: $btn-color-primary-hover;
            border-color: $btn-color-primary-border-hover;
        }

        &.disabled {
            background: lighten($btn-color-primary, 1%);
            outline: 0 !important;
            border: none;

            &:hover {
                background: lighten($btn-color-primary, 1%);
                cursor: default;
            }
        }
    }

    &.btn-info {
        background: $btn-color-info;
        border-color: $btn-color-info-border;

        &:hover {
            background-color: $btn-color-info-hover;
            border-color: $btn-color-info-border-hover;
        }
    }

    &.btn-info--done {
        color: #fff;
        background: darken($btn-color-info, 20%);
        border-color: darken($btn-color-info-border, 20%);

        &:hover {
            background-color: darken($btn-color-info-hover, 20%);
            border-color: darken($btn-color-info-border-hover, 20%);
        }
    }

    &.btn-add-another {
        svg {
            margin: 0 0.25em 0 0;
        }
    }

    &.btn-secondary {
        color: #fff;
        background-color: #5a6268;
        border-color: #545b62;

        &:hover {
            background-color: #6c757d;
            border-color: #6c757d;
        }
    }

    &.btn-danger {
        color: #fff;
        background-color: #c82333;
        border-color: #bd2130;

        &:hover {
            background-color: #dc3545;
            border-color: #dc3545;
        }
    }

    &.btn-success {
        color: #fff;
        background-color: #28a745;
        border-color: #28a745;

        svg {
            path {
                fill: #fff;
            }
        }
    }

    &.btn-no-bg {
        // background: none transparent;
        // border: none transparent;
        // color: #3d79b9;
        // text-shadow: 0 0 1.25em #19599e8f;
        // border: 2px solid transparent;

        color: #fff;
        border-top: 1px solid #19426b8f;
        border-right: 1px inset #28a745;
        border-bottom: 1px solid #28a745;
        border-left: 1px solid #19426b8f;

        // filter: drop-shadow(0px 0px 3px rgb(0, 0, 0));
        background: linear-gradient(145deg, #378eeb, #28a745);
        background-size: 150% 100%;
        border-radius: 2px;

        &:hover {
            animation: btnPrimaryToSuccess 0.2s ease 1;
            animation-fill-mode: forwards;
            border-top: 1px solid #19426b8f;
            // border-right: 1px inset #28a745;
            // border-bottom: 1px solid #28a745;
            border-left: 1px solid #19426b8f;

        }
    }

    &.btn--link {
        background: none;
        color: $colorPrimary;
        border: 0px none;
        font-size: 0.9em;
        padding: 0;
        margin: 0 !important;
        width: auto;

        &:hover,
        &:focus,
        &:active {
            color: lighten($colorPrimary, 15%) !important;
            background: none !important;
            border: 0px none !important;
        }
    }

    &.btn-inline {
        display: inline;
    }

    @keyframes btnPrimaryToSuccess {
        0% {
            background-position: 0% 50%
        }

        50% {
            background-position: 50% 100%
        }

        100% {
            background-position: 100% 150%
        }
    }



    &.icon--white {
        svg {
            margin-right: 0.5em;

            path {
                fill: #fff;
            }
        }
    }

    &.new-invoice {
        margin-bottom: 1em;
    }

    &.button--user-story-to-do,
    &.button--user-story-todo {
        background: $colorTodo;
        border-color: darken($colorTodo, 15%);

        &:hover {
            background: lighten($colorTodo, 15%);
            border-color: darken($colorTodo, 15%);
        }

        &:not(:disabled):not(.disabled).active,
        &:not(:disabled):not(.disabled):active {
            background: lighten($colorTodo, 15%);
        }
    }

    &.button--user-story-in-progress {
        background: $colorInProgress;
        border-color: darken($colorInProgress, 15%);

        &:hover {
            background: lighten($colorInProgress, 15%);
            border-color: darken($colorInProgress, 15%);
        }

        &:not(:disabled):not(.disabled).active,
        &:not(:disabled):not(.disabled):active {
            background: lighten($colorInProgress, 15%);
        }
    }

    &.button--user-story-done {
        background: $colorDone;
        border-color: darken($colorDone, 15%);

        &:hover {
            background: lighten($colorDone, 15%);
            border-color: darken($colorDone, 15%);
        }

        &:not(:disabled):not(.disabled).active,
        &:not(:disabled):not(.disabled):active {
            background: lighten($colorDone, 15%);
        }
    }
}

.click-to-unlock {
    position: relative;
    min-height: 3em;
    margin-top: 2em;

    .lock-container {
        display: flex;
        align-items: center;
        position: relative;
        width: 10em;
    }


    &.delete-project {

        .lock {
            position: absolute;
            left: 2.5em;
            bottom: 2.5em;
            border-color: red;

            &:before,
            &:after {
                border-color: red;
            }

            &:after {
                background: red;
            }

            transition: 0.2s all;
            opacity: 0;
        }

        &:hover {

            .lock {
                transition: 0.2s all;
                opacity: 1;
                border-color: red;

                &:before,
                &:after {
                    border-color: red;
                }

                &:after {
                    background: red;
                }
            }
        }

        .lock-container.unlocked {
            width: auto;
            height: auto;

            svg {
                position: relative;
                bottom: -0.1em;
            }
        }
    }

    .logout {
        padding: 0.05em 0.1em;
        width: 6em;
        margin: 0 auto 0 2em;
        transform: translateY(-30%);

        &.disabled {
            &:hover {
                background-color: #c82333;
                border-color: #bd2130;
            }
        }

        svg {
            margin-left: 0.5em;

            path {
                fill: rgb(231, 231, 231);
            }
        }
    }


    .save {
        &.disabled {
            &:hover {
                background-color: #026312;
                border-color: #008b17;
            }
        }
    }




    /* Locked */
    .lock {
        width: 1.75em;
        height: 1.4em;
        border: 3px solid #7e7e7e;
        border-radius: 5px;
        position: relative;
        cursor: pointer;
        -webkit-transition: all 0.1s ease-in-out;
        transition: all 0.1s ease-in-out;
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        z-index: 2;
    }

    .lock:after {
        content: "";
        display: block;
        background: #7e7e7e;
        width: 3px;
        height: 7px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -3.5px 0 0 -2px;
        -webkit-transition: all 0.1s ease-in-out;
        transition: all 0.1s ease-in-out;
    }

    .lock:before {
        content: "";
        display: block;
        width: 1em;
        height: 0.75em;
        bottom: 100%;
        position: absolute;
        left: 50%;
        margin-left: -0.5em;
        border: 3px solid #7e7e7e;
        border-top-right-radius: 50%;
        border-top-left-radius: 50%;
        border-bottom: 0;
        -webkit-transition: all 0.1s ease-in-out;
        transition: all 0.1s ease-in-out;
    }

    /* Locked Hover */
    .lock:hover:before {
        height: 0.85em;
    }

    /* Unlocked */
    .unlocked {
        width: 1.75em;
        height: 1.4em;
    }

    .unlocked:before {
        bottom: 130%;
        left: 31%;
        margin-left: -0.6em;
        transform: rotate(-45deg);
    }

    .unlocked,
    .unlocked:before {
        border-color: #ff5153;
    }

    .unlocked:after {
        background: #ff5153;
    }

    /* Unlocked Hover */
    .unlocked:hover:before {
        height: 0.75em;
        left: 40%;
        bottom: 124%;
        transform: rotate(-30deg);
    }
}




.icon--right {
    text-align: right;
    float: right;
    position: relative;
    z-index: 2;

    &:hover {
        cursor: pointer;
    }
}

.btn.icon--small {
    width: 1.5em;
    height: 1.6em;
    padding: 0;
    font-size: 1em;
}

.btn.icon--helper {
    display: inline-block;
    background-color: #ffffff;
    border-color: #e5ebff;
    color: #d3dcf7;
    border-radius: 1em;

    &:hover {
        color: #b8c4ec;
        border-color: #b8c4ec;
        background-color: #fff;
    }
}

.icon--shadow {
    box-shadow: 0px 0px 4px #e0e4e7;
    padding: 0.25em;
    height: 1.5em;
    width: 1.5em;
    transition: all 0.2s;
    color: #7e7e7e;

    &:hover {
        transform: scale(1.5);
        transition: all 0.12s;
        color: #000;
    }
}

.delete-goal {
    &:hover {
        cursor: pointer;

        path {
            fill: red;
        }
    }
}


.icon--delete {
    width: 4em;

    &:hover {
        cursor: pointer;

        path {
            fill: red;
        }

        &:after {
            content: "Delete";
            display: inline-block;
            margin-left: 0.5em;
            font-weight: bold;
            background: red;
            color: #fff;
            border-radius: 3px;
            font-size: 0.7em;
            padding: 0.25em 0.35em;
        }
    }
}


.contract--item-status {
    float: right;

    .btn {
        padding: 0.2em 0.5em;
        background: none;
        border-top: 0px none;
        border-right: 0px none;
        border-bottom: 1px solid #ddd;
        border-left: 0px none;
        border-radius: 0;
        color: #000;
        font-size: 0.8em;

        &.draft {

            &.active,
            &:hover {
                background: $colorTodo;
                box-shadow: none !important;
            }
        }

        &.review {

            &.active,
            &:hover {
                background: $colorInProgress;
                box-shadow: none !important;
            }
        }

        &.done {

            &.active,
            &:hover {
                background: $colorDone;
                box-shadow: none !important;
            }
        }

    }
}

.start-new-meeting {
    max-width: 13em;
    float: right;
    position: relative;
    top: 0.4em;

    @include mq($max, $tiny) {
        max-width: 11em;
        top: -1.15em;
    }
}

button.pay-now {
    background: saturate(#28a745, 25%);

    &:hover {
        background: saturate(#28a745, 50%);
    }

    &.pay-now__off {
        background: #6d8f74;
        border-color: #5a5a5a;

        &:hover {
            background: #6d8f74;
            cursor: initial;
        }
    }
}



.task-board {
    .accordion {
        .accordion-button {
            background: none transparent;
            border-radius: 0.5rem;
            margin-left: 0.5rem;
            border: 0px none;
            position: absolute;
            top: 0;
            width: 90%;
            left: 0;
            padding: 1em 1em 0.5em 1em;

            .inside-button {
                display: none;
            }

            &.collapsed {
                bottom: -1rem;
                width: 100%;
                margin: 0;

                .inside-button {
                    width: 100%;
                    height: 0.5rem;
                    position: absolute;
                    bottom: 0.5rem;
                    color: #bebebe;
                    text-align: center;
                    margin: 0 0 0 -1rem;
                    display: block;
                }
            }
        }

        .accordion-header {
            width: 100%;
            height: 100%;

            .accordion-button {
                bottom: -1rem;
                width: 100%;
                margin: 0;
                padding: 0;
            }
        }

        .show-less {
            bottom: 0;
        }
    }
}