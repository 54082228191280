.nav-project,
.nav-dashboard,
.nav-user-profile {
    float: left;
    height: 100vh;

    &.show-menu--false {
        min-width: 5em;

        @include mq($max, $small) {
            position: absolute;
        }
    }

    @include mq($max, $small) {
        min-width: 5em;
    }

    &:hover {
        .nav--label {
            color: #fff;
        }
    }


    .bm-burger-button {
        position: fixed;
        width: 36px;
        height: 30px;
        top: 0.65em;
        left: 1.55em;
        z-index: 102;
        transition: 0.25s all;

        button#react-burger-menu-btn {
            width: 83px;
        }

        .bm-burger-bars {
            background: $colorNav;
        }

        &:hover {
            .bm-burger-bars {
                background: #fff;
            }

            .main-menu-label {
                color: #fff;
            }
        }
    }

    .show-menu--true {
        .bm-burger-button {
            @include mq($min, $small) {
                left: 0.5em;
                transition: 0.25s all;
            }
        }
    }


    .nav--label {
        position: fixed;
        padding: 1em 0 0 4em;
        width: 7em;
        height: 3.5em;
        top: 0;
        left: -2.5em;
        transform: translateX(3.5em);
        color: $colorNav;
        font-size: 1em;
        font-weight: bold;
        z-index: 102;
        transition: 0.25s all;

        &:hover {
            cursor: pointer;
        }

        &.menu-open-true {}
    }

    .all-projects {
        position: fixed;
        top: 0;
        left: 9em;
        height: 3.5em;
        z-index: 999;
        border-bottom: 0px none;

        &:hover {
            background: none;
            border-bottom: 0px none;
        }

        svg {
            width: 1.5em;
            height: 1.5em;
            position: relative;
            top: 0.2em;
        }
    }


    /* Color/shape of burger icon bars on hover*/
    .bm-burger-bars-hover {
        background: #fff;
        opacity: 1 !important;
    }


    /* Position and sizing of clickable cross button */
    .bm-cross-button {
        display: none;
        position: fixed !important;
        left: 260px;

        @include mq($max, $small) {
            display: none;
        }

        &:hover {
            span {
                background: #fff;
            }
        }
    }

    /* Color/shape of close button cross */
    .bm-cross {
        background: #bdc3c7;
    }

    .bm-menu-wrap {
        position: relative;
        height: 100%;
        background: $colorNavBG;
        left: 0;
        bottom: 0;
        top: 3.5em;
        z-index: 101 !important;
        display: flex !important;
        flex-direction: column;
        align-items: stretch;
        transition: none !important;

        @include mq($max, $small) {
            transition: none !important;
        }

        &.show-menu--true {
            width: 19em !important;
            position: relative !important;

            @include mq($max, $small) {
                position: fixed !important;
            }
        }

        &.show-menu--false {
            width: 5em !important;
            transform: translate3d(0, 0, 0) !important;

            @include mq($max, $small) {
                left: -5em;
            }

            .nav--text,
            .nav-link {
                display: none;
            }

            .bm-item.accordion .card-header {
                &:after {
                    display: none;
                }
            }

            .bm-item.accordion .collapse.show {
                &:before {
                    display: none;
                }
            }



            .timer--mini {
                margin-left: -1em;
                font-size: 0.6em;
            }

            .bm-cross-button {
                display: none;
            }
        }
    }


    .bm-item .accordion-item {
        .card-header {
            padding: 0;
            height: 4rem;

            &::after {
                position: relative;
                bottom: 2em;
            }

            span {
                font-size: 1rem;
            }
        }

        button {
            background: none transparent;
            padding: 1em 1.5em;
            border: 0px none;
            text-align: left;
            display: block;
        }
    }

    .bm-menu {
        background: #424242;
        font-size: 1.15em;
        position: fixed;
        width: inherit;

        @include mq($max, $small) {
            font-size: 1em;
        }
    }

    .menu-section {
        background: $colorNavBG;

        &:nth-child(even) {
            margin-top: 2em;
            border-bottom: 1px solid rgba(0, 0, 0, .125);
        }

        &:nth-child(odd) {
            background: darken($colorNavBG, 5%);
            margin-top: 0;
            padding-top: 1em;
        }

        p {
            color: #7d7d7d;
            font-size: 1.1em;
            padding: 0.75rem 0.8em 0 0.8em;
        }
    }

    /* Morph shape necessary with bubble or elastic */
    .bm-morph-shape {
        fill: #424242;
    }

    /* Wrapper for item list */
    .bm-item-list {
        color: #b8b7ad;
    }

    /* Individual item */
    .bm-item {
        display: inline-block;
        outline: none;
        position: relative;
        z-index: 2;
    }

    button.btn-info {
        color: #dbdbdb;
    }

    /* Styling of overlay */
    .bm-overlay {
        background: rgba(0, 0, 0, 0.3);
        position: fixed;
        left: 0;
        z-index: 99 !important;
    }


    nav {
        width: 100%;
    }

    .card {
        padding: 0;
        background: none;
    }

    .card-body {
        padding: 0;
    }

    .accordion {
        .card-header {
            color: $colorNavText;
            border-bottom: 1px solid rgb(54, 54, 54);
            padding: 0.75em 0.8em 0.75em 1.18em;

            &:hover {
                cursor: pointer;
                border-bottom: 1px solid #333;

                &:after {
                    background: #555;
                }
            }

            &:after {
                content: "+";
                width: 1em;
                height: 1em;
                color: #fff;
                float: right;
                transition: all 0.25s;
            }
        }
    }

    a,
    span.nav-link {
        font-family: 'Muli', Helvetica, sans-serif;
        font-size: 0.98em;
        font-weight: 600;
        display: block;
        padding: 0.75em 0.8em 0.75em 1em;
        color: $colorNavText;
        transition: all 0.25s;
        border-left: 4px solid transparent;
        letter-spacing: 0.5px;

        @include mq($max, $small) {
            letter-spacing: normal;
        }

        &:hover,
        &.active {
            background: #555;
            transition: all 0.25s;
            color: #fff;

            .icon {
                path {
                    fill: #fff;
                }

                // &.ani-blink{
                //     animation: blink 0.7s 1 linear;
                // }
            }
        }

        &.active {
            border-left: 4px solid red;
        }
    }



    a {
        border-bottom: 1px solid rgb(54, 54, 54);

        // text-shadow: 1px 2px 1px rgb(51, 51, 51);
        &:hover {
            border-bottom: 1px solid #333;
            text-decoration: none;
        }
    }

    span.nav-link {
        margin: 0;
        padding: 0;
        display: inline-block;
        color: $colorNavText;
    }

    .card-header {
        transition: background 0.25s;

        // text-shadow: 1px 2px 1px rgb(51, 51, 51);
        &:hover {
            background: #555;
            transition: background 0.25s;

            ~.collapse.show {
                &:before {
                    background: #555;
                }
            }

            span.nav-link {
                color: #fff;
            }

            .icon {
                path {
                    fill: #fff;
                    transition: fill 0.25s;
                }

                // &.ani-brush{
                //     animation: brush 1.3s infinite ease;
                // }
            }
        }
    }

    .icon {
        width: 2em;

        path {
            fill: #cecece;
            transition: fill 0.25s;
        }
    }

    .collapse,
    .collapsing {
        background: #333;
        position: relative;

        a {
            display: block;
            padding-left: 1em;
            font-size: 0.94em;
            border-bottom: 1px solid rgb(42, 42, 42);

            &:hover {
                background: #282828;
            }

        }

        a:last-child {
            border-bottom: 1px solid #000;
        }

        &:before {
            content: "-";
            background: #404040;
            height: 1em;
            width: 1em;
            text-align: center;
            position: absolute;
            top: -2.25em;
            right: 0.2em;
            color: #fff;
            line-height: 0.7em;
            transition: all 0.25s;
            opacity: 0;
        }
    }

    .collapse.show {
        &:before {
            opacity: 1;
            transition: all 0.25s;
        }
    }

    .nav-close {
        float: right;
        height: 2em;
        padding: 0.5em 1em 2em 0.5em;
        z-index: 1;

        @include mq($max, $tiny) {
            position: relative;
            bottom: 3em;
        }

        &:hover {
            cursor: pointer;

            svg {
                path {
                    fill: #fff;
                }
            }
        }
    }

    .nav--bottom {
        position: absolute;
        z-index: 3;
        right: 0;
        bottom: 3.45em;
        border-color: #545b62;

        @include mq($max, $small) {
            margin: 12.75em 0 0 0;
        }

        @include mq($max, $tiny) {
            margin: 3.5em 0 0 0;
        }

        @include mq($min, $small) {
            position: absolute;
            bottom: 3em;
            margin: 0;
        }
    }


    .dash-help {
        color: #fff;
        padding: 1em;
        font-size: 0.8em;
    }

    .count {
        color: #fff;
        padding: 0.05em;
        float: right;
    }

    .timer--mini {
        display: inline-block;
        margin-left: 1em;
        color: #fff;
    }
}



.nav--text__dashboard {
    display: block;
    position: absolute;
    top: 0.95em;
    left: 3em;

    @include mq($max, $small) {
        display: none;
    }
}

.show-menu--false {
    .nav--text__dashboard {
        display: none;
    }
}

.header--button {
    position: fixed;
    top: 0.35em;
    left: 0.5em;
    z-index: 5;
    width: 50%;

    button {
        width: 50%;

        @include mq($max, $small) {
            width: 75%;
        }
    }
}

.beta {
    background: #e44040 !important;
    padding: 0.1em 0.4em !important;
    text-align: center !important;
    font-size: 0.8em !important;
    border-radius: 2em;

    &:after {
        content: "🔨💩🧰";
    }
}

// @keyframes blink {
//     0% { transform: scaleY(1); }
//     49% { transform: scaleY(1); }
//     60% { transform: scaleY(0); }
//     70% { transform: scaleY(1); }
//     100% { transform: scaleY(1); }
// }

// @keyframes brush {
//     0% { transform: rotate(0deg); }
//     50% { transform: rotate(-70deg); }
//     80% { transform: rotate(0deg); }    
//     100% { transform: rotate(0deg); }
// }