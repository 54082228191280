
  
//   .SignIn {
//     @extend %authentication;
//   }
  
//   .SignUp {
//     @extend %authentication;
//   }

.header{
    width: 100%;
    background: #424242;
    height: 3.5em;
    padding: 0.5em;
    z-index: 101;
    position: fixed;
    top: 0;

    .CurrentUser {
    //     &:before{
    //         position: absolute;
    //         right: 15em;
    //         color: #fff;
    //         content: "Default"
    //     }

    //     .min-size,
    //     .max-size{
    //         text-align: center; 
    //         color: #fff;
    //     }



    // // MIN
    //     .min-size{
    //         @include mq($min, $tiny){
    //             &:before{
    //                 content: "Min Tiny";
    //                 right: 20em;
    //             }
    //         }
    //         @include mq($min, $small){
    //             &:before{
    //                 content: "Min Small";
    //                 right: 20em;
    //             }
    //         }
    //         @include mq($min, $medium){
    //             &:before{
    //                 content: "Min Medium";
    //                 right: 20em;
    //             }
    //         }
    //         @include mq($min, $desktop){
    //             &:before{
    //                 content: "Min Desktop";
    //                 right: 20em;
    //             }
    //         }
    //         @include mq($min, $large){
    //             &:before{
    //                 content: "Min Large";
    //                 right: 20em;
    //             }
    //         }
    //     }



    // // MAX
    //     .max-size{
    //         @include mq($max, $large){
    //             &:before{
    //                 content: "Max Large";
    //             }
    //         }
    //         @include mq($max, $desktop){
    //             &:before{
    //                 content: "Max Desktop";
    //             }
    //         }
    //         @include mq($max, $medium){
    //             &:before{
    //                 content: "Max Medium";
    //             }
    //         }
    //         @include mq($max, $small){
    //             &:before{
    //                 content: "Max Small";
    //             }
    //         }
    //         @include mq($max, $tiny){
    //             &:before{
    //                 content: "Max Tiny";
    //             }
    //         }
    //     }
       

        .all-projects{
            font-weight: bold;
            display: inline-block;
            width: 260px;
            margin: -0.5em 0 0 -0.5em;
            padding: .75rem 1.25rem;
            color: #cecece;
            &:hover{
                background:#555;
                color: #fff;
            }
        }

        .CurrentUser--profile {
          margin-bottom: 0.5em;
        }
        .CurrentUser--information {
            float: right;
        }
        a.user-img-link{
          display: block;
          float: left;
        }
        .name{
            float: left;
            margin-left: 1em;
            color: #fff;
        }
        img {
          max-height: 40px;
          max-width: 40px;
      
        }
        h2 {
          margin-bottom: 1em;
        }
        .created-at:before {
          content: 'Joined';
          color: $comment-highlight-color;
          font-weight: bold;
          margin: 0.5em 0.5em;
        }
        .email:before {
          content: "";
          border-left: 5px solid $info-color;
          color: $comment-highlight-color;
          font-weight: bold;
          margin: 0.5em 0.5em;
        }
      }
    button{
        padding: 0;
    }
}

.homepage{
    .header{
        background: #424242;
        @include mq($max, $tiny){
            
        }
    }
}

.signinandup{
    .inup{
        position: relative;
        z-index: 3;
    }
}


.welcome{
    color: #fff;
    margin-top: -2em;
    position: relative;
    z-index: 3;
}