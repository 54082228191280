.slowly{
    animation-duration: 2s;
}
.fast{
    animation-duration: 0.32s;
}
.forwards{
    animation-fill-mode: forwards;
}
.delayed{
    opacity: 0;
    animation-delay: 0.25s;
}

@-webkit-keyframes fadeIn {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1;
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1;
    }
  }
  
  .fadeIn {
    animation-name: fadeIn;
  }

  
@-webkit-keyframes fadeOut {
    from {
      opacity: 1;
    }
  
    to {
      opacity: 0;
    }
  }
  
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
  
    to {
      opacity: 0;
    }
  }
  
  .fadeOut {
    animation-name: fadeOut;
  }


  @keyframes chatBounceInUp {
    from,
    60%,
    75%,
    90%,
    to {
        animation-timing-function: linear;
    }
  
    from {
        height: 0;
    //   transform: translate3d(0, 69vh, 0);
    }
    
    40%{
        // height: 10vh;
    }
    60% {
        // height: 20vh;
    //   transform: translate3d(0, -20px, 0);
    }
  
    75% {
        // height: 50vh;
    //   transform: translate3d(0, 10px, 0);
    }
  
    90% {
        // height: 50vh;
    //   transform: translate3d(0, -5px, 0);
    }
    99%{
        height: 75vh;
    }
    to {
        height: auto;
    }
  }
  
  .bounceInUp {
    animation-name: chatBounceInUp;
  }


  @keyframes chatBounceOutDown {
    from,
    60%,
    75%,
    90%,
    to {
        animation-timing-function: linear;
    }
  
    0%{
        height: auto;
    }
    1%{
        height: 80%;
    }
   
    to {
        height: 2.5em;
        }
    }
  
  .bounceOutDown {
    animation-name: chatBounceOutDown;
  }