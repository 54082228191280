@import url("https://use.fontawesome.com/releases/v5.8.1/css/all.css");


.card{
    background-color: #fff;
    background-clip: border-box;
    border: 0px none;
    border-radius: 3px;
    box-shadow: 2px 2px 2px 0 rgba(102,123,139,.16);
    padding: 1em;
    margin: 1em auto;
    transition: all 0.37s;
    
    h3{
        text-align: center;
    }

    h4{
        font-weight: bold;
        padding: 0;
        width: auto;
        display: block;
    }


    button.btn{
        margin: 0.5em auto 0.5em auto;
    }
    .btn.save{
        margin: 0;
        border-radius: 5px 5px 0 0;
    }
    &.card--user-stories{
        padding-bottom: 0;
    }

  

    // Card Animations
    &.ani-fall3d{
        animation: textEffec3DFall 0.37s ease 1;
    }

    &.ani-fadeOut{
        animation: fadeOut 0.37s ease 1;
    }
    &.ani-reverse{
        animation-direction: reverse;
    }
}

.card--goals{
    .bounds{
        display: none;
        width: 100%;
        height: 350px;
        overflow: hidden;
        top: 0;
        left: 0;
        position: absolute;
    }
}

 
.card--participant{
    &.card--participant--toggle{
        float: left;
        margin: 0 1em 1em 0;
    }
    .card--participant__contact-info{
        min-height: 10em;
    }
    .card--participant__project-info{
        min-height: 5em;
    }
}

.card--participant,
.card--meeting-responsibility{
    padding: 1.5em 1em 1em 1em;
    word-break: break-word;
    border: 2px solid transparent;
    &.present-at-meeting{
        position: relative;
        border: 2px solid #999;
        color: $colorTextBody;
        &:after{
            font-family: "Font Awesome 5 Free";
            content: "\f058";
            font-size: 7em;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

.add-meeting .card--participant{
    &:hover{
        cursor: pointer;
    }
}

.card--meeting-responsibility{
    &:hover{
        cursor: pointer;
    }
    &.present-at-meeting{
        &:after{
            font-size: 5em;
            left: initial;
            right: 0;
        }
    }
}

.add-user-story .card{
    padding: 0 1em;
    margin: 3.5em 0;
    border-top: 2px solid #eee;
    border-left: 2px solid #eee;
    &:first-child{
        margin-top: 0;
    }
    &:nth-last-child(1){
        margin-bottom: 0;
    }
    &.changed-true{
        &:after{
            transition: all 0.37s;
            content: "Unsaved Changes";
            background: red;
            position: absolute;
            font-size: 0.8em;
            top: 0;
            left: 0;
            color: #000;
            padding: 0.25em 0.5em;
            color: #fff;
        }
    }
}


.dark-card{
    background: $darkCard;
    &:hover{
        background: $darkCardHover;
    }
}



.card--contract-summary{
    box-shadow: none;
    background: rgb(253, 253, 249);    
    border: 1px dashed rgb(230, 230, 230);
}

// Discovery Page, 
.card .icon-group,
.icon-group {
    
    svg {
        display: inline-block;
        width: 0.75em;
        height: 0.75em;
        margin: 0 0.05em;
        font-size: 2em;
        path {
            fill: lighten($icon-color, 35%);
        }
    }

    h2,
    h3 {
        display: inline;
        margin-right: 0.5em;
        text-align: left;
    }

    &.group--goals{
        position: relative;
        width: 100%;
        height: 100%;

        .fa-chart-line{
            font-size: 50em;
            // -webkit-filter: drop-shadow(-3px -1px 1px #f1f1f1);
            filter: drop-shadow(-2px 4px 1px #f8f8f8);
            path{
                fill: #fafafa;
            }
        }
        .icon--background{
            position: absolute;
            top: -50px;
            left: -120px;
        }
    }
}


.Project{
    margin: 1em 0;
    .card{
        margin: 0;
    }
    &:hover{
        cursor: pointer;
        .card{
            // background: rgb(233, 233, 233);
        }
    }
    a{
        &:hover{
            text-decoration: none;
        }
    }
}



@keyframes textEffec3DFall {
    0% { transform: perspective(10px) translateZ(2px); }
    100% { transform: perspective(10px) translateZ(0px); }
}

@keyframes fadeOut {
    0% { opacity: 1; }
    100% { opacity: 0; }
}

.card.timer{
    margin-top: 1em;
    border-bottom: 2px solid gray;
    padding: 5px;

    .timer--timeCode{
        padding: 0.5em 0.5em;
        
        &:before{
            content: "hh:mm:ss";
            font-size: 0.5em;
            position: absolute;
            z-index: 2;
            top: 4.5em;
            left: 50%;
            transform: translateX(-50%);
            letter-spacing: 4.3px;
            opacity: 0;
            transition: opacity 0.37s;
        }
        &:after{
            content: "24hr max";
            position: absolute;
            font-size: 0.37em;
            top: 6.5em;
            left: 85%;
            transform: translateX(-50%);
            opacity: 0;
            transition: opacity 0.37s;
        }
        &:hover{
            &:before,
            &:after{
                opacity: 0.25;
                transition: opacity 0.37s;
            }
            cursor: default;
        }
    }

    .trash,
    .clock{
        z-index: 2;
        float: right;
        top: 0;
        color: #acacac;
    }
    .decimal{
        font-size: 1.8em;
        border-radius: 0px;
        padding: 0;
        text-align: center;
        min-height: auto;
    }
    .notes{
        margin-top: 1.5em;
        height: 100%;
        min-height: 9.8em;
        resize: none;
        border-radius: 0;
    }
}



.card-hover{
    &:hover{
        background: linear-gradient(48deg, #ffffff, #f1f1f1, #eee, #fff);
        background-size: 400% 100%;
        animation: CardHover 1.48s ease 1;
    }
    a p{
        color: $colorTextBody;
    }
}

@keyframes CardHover {
    0%{background-position:0% 50%}
    50%{background-position:100% 51%}
    100%{background-position:0% 50%}
}



.card--drawing-tool{
    button.MuiIconButton-colorPrimary{
        // width: 3em;
        width: auto;
        margin: 0;
    }
    
    .main-buttons .MuiSvgIcon-root{
        color: #000;
    }

    .MuiInputBase-root,
    .MuiToolbar-regular,
    .MuiSvgIcon-root,
    .MuiFormHelperText-root{
        color: #fff;
        svg{
            color: #fff;
        }
        .active{
            .MuiSvgIcon-root{
                color: $colorPrimary;
            }
            svg{
                color: $colorPrimary;
            }
        }
    }

    .MuiSlider-root{
        color: #fff;
    }

    .MuiIconButton-colorPrimary:hover{
        background: $colorSecondary;
    }

    .add-text-input{
        width: 68.5%;
    }
    .add-text-btn,
    .remove-selected-btn{
        position: relative;
        bottom: 0.25em;
    }



    .select-tool-btn{
        min-width: 48px;
    }
    .inline-btn{
        width: auto;
        margin: 0;
    }

    .compact-picker{
        width: auto;
    }

}