// Styles for: components/sitemap.jsx

$chartBG: rgb(255, 255, 255);
$darkPercent: 2%;
$tier2: darken($chartBG, 3%);
$tier3: darken($tier2, $darkPercent);
$tier4: darken($tier3, $darkPercent);
$tier5: darken($tier4, $darkPercent);

.modal{
    .modal-content{
        padding: 3em;
        .modal-footer{
            button:not(:last-child){
                margin-bottom: 0;
            }
        }
    }
}

.sitemap-and-content{

    /*************************************/
    /* Draggable Grid */
    /*************************************/
    .draggableGrid {
        .react-grid-item {
            overflow: hidden;

            div {
                height: 100%;;
            }

            img {
                height: 100%;
                width: 100%;
            }
        }

        .draggable-img {
            pointer-events: none;
        }

        .react-grid-item {
            border: 1px solid #000;
        }
    }

    .ul-tier{
        li{
            list-style: none;
            list-style-type: none;
        }
    }

    .drop-zone{
        min-height: 5em;
        border: 1px dashed transparent;
    }

    .pages{
        display: flex;
        flex-flow:row;
        overflow-x: scroll;
        padding: 0 1em;
        background: rgb(107, 107, 107);
        @include mq($min, $large){
            max-width: 88%;
        }
        
    
        &.menuOpen-true{
            @include mq($min, $large){
                max-width: 100%;
            }
        }
        &.menuOpen-false{
            @include mq($max, $medium){
                max-width: 100%;
            }
        }
        #tier-1-wrapper{
            min-width:200px;
        }
        
        .other-tiers{
            flex: 1;
            min-width:200px;
            margin: 0 0.5em 0 0;
        }

        .card{
            overflow: visible;
            border-top: 1px solid rgb(235, 235, 235);
            border-left: 1px solid rgb(235, 235, 235);
            margin-bottom: 2em;
       
            &.card-tier{
                padding-top: 0;
                position: relative;
                .addSubpage{
                    opacity: 0;
                }
                .card-tier--controls{
                    padding: 0.5em 0 0 0;
                    position: absolute;
                    top: 0em;
                    left: 0;
                    width: 100%;
                    opacity: 0;
                    z-index: 1;
                    transition: opacity 0.25s;
                    &.editing{
                        opacity: 1;

                    }
                }
                .card-tier--title{
                    text-align: center;
                }

                &:hover{
                    .card-tier--controls,
                    .addSubpage{
                        opacity: 1;
                        transition: opacity 0.25s;
                    }
                    svg{
                        opacity: 1;
                        transition: opacity 0.25s;
                    }
                    .subpage--droppable{
                        background: #d4d4d4;
                    }
                    .drop-zone{
                        border: 1px dashed #afafaf;
                    }
                    .card-page{
                        background: #fff;
                        &:hover{
                            box-shadow: 2px 2px 2px 0 rgba(61, 61, 61, 0.5)
                        }
                    }
                }

                .show-more,
                .show-less{
                    bottom: 0;
                }

    
                ul.ul-tier{
                    margin: 0;
                    li.li-page{
                        margin: 0;
                        list-style-type: none;
                        align-items: center;
                        .page--title{
                            word-wrap: break-word;
                        }
                    }
                }

                .card-page{
                    padding: 0.5em 1em;
                    @include mq($max, $medium){
                        padding: 0.5em 0.5em;
                    }
                    position: relative;
                    .card-page--controls,
                    .card-subpage--controls{
                        background: #c4c4c49a;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        z-index: 1;
                    }




                    .edit-mode.rdw-editor-wrapper{
                        cursor: initial;
                    }
                    svg{
                        opacity: 0;
                        transition: opacity 0.25s;
                        font-size: 1.5em;
                   
                    }
                    &:hover{
                        .card-page--controls,
                        .card-subpage--controls{
                            background: #c4c4c4;
                        }
                        svg{
                            opacity: 1;
                        }
                    }
                }
                .subpage-card{
                    padding-top: 2em;
                    min-height: 5em;
                    .subpage--fields{
                        margin-top: 0.5em;
                    }
                }
                .subpage--droppable{
                    background: #e0e0e0;
                    padding: 0.5em;
                    &:hover{
                        background: #d4d4d4;
                    }
                }
            }
        }
    }

    .edit-tier{
        margin-top: 4em;
    }
    .edit-page{
        margin-top: 2.5em;
    }

    .card-header{
        padding: 0.25em 0;
        h1,h2,h3,h4,h5{
            display: inline-block;
        }

        h1,h2,h3,h4,h5{
            margin: 0;
        }
        &:hover{
            h1,h2,h3,h4,h5{
                text-decoration: underline;
            }
        }
    }

    .trash-fix{
        font-size: 0.7em;
        z-index: 1;
        float: right;
        top: 0;
        height: 2em;
        &:after{
            font-size: 1em;
        }
        &.trash-fix-subpage{
            top: 0.5em;
        }
    }
    

    .addSubpage,
    .toggleEdit{
        height: 2em;
        float: left;
        position: relative;
        z-index: 99;
        &:after{
            opacity: 0;
            position: absolute;
            font-size: 0.7em;
            background: #eee;
            padding: 0.1em 0.162em;
            border-radius: 3px;
            border: 1px solid rgb(199, 199, 199);
            transition: all 0.35s;
            left: 0;
            text-align: center;
        }
        &:hover{
            cursor: pointer;
            &:after{
                opacity: 1;
                transition: all 0.35s;
            }
        }
        svg{
            width: 0.65em;
            height: 0.65em;
        }
    }

    .toggleEdit{
        &:hover{
            path{
                fill: #000;
            }
        }
        &:after{
            content: "Edit";
            right: 0em;
            bottom: 3em;            
        }
    }


    .addSubpage{
        left: -1em;
        &:hover{
            path{
                fill: rgb(38, 164, 248);
            }
        }
        &:after{
            position: absolute;
            left: -1.5em;
            bottom: 3em;
            content: "Add Subpage";
            cursor: initial;
            min-width: 8em;
            padding: 0.1em 0.75em;
        }
    }

    ul.ul-tier{
        .toggleEdit{
            z-index: 999;
        }
        .addSubpage{
            height: 1em;
            &:after{
                bottom: 1em;
            }
        }
    }

    .homepage-wrapper{
        max-width: 75%;
        margin: 0 auto;
        @include mq($max, $medium){
            max-width: 100%;
        }
    }


    .pages,
    .homepage-wrapper{
        .icon{
            width: 1.7em;
        }
        .card-header{
            position: relative;
            background: none transparent;
            border-bottom: 0px none;
        }

        // Breadcrumbs
        .page--breadcrumb {
            font-size: 0.65em;
            color: lighten($colorTextBody, 15%);
            line-height: 1.5;
            margin: 0em 0 3em 0;
                span:before {
                    content: "/";
                    padding-right: 0.4em;
                    color: #999;
                }
                span:first-child:before {
                    content: "";
                    padding-right: 0; 
                }
        }

        .page--title{
            margin: -1em 0 0 0;
            padding: 0;
            text-align: center;
        }

        .has-subpages{
            transition: all 0.3s;
            &:hover{
                transition: all 0.3s;
            }
        }

        .page--teaser,
        .page--body{
            white-space: pre-wrap;
            overflow: hidden;
            max-height: 100px;
            position: relative;
            transition: all 0.3s;
            .rdw-editor-main{
                overflow: hidden;
            }
            &:hover{
                background: none;
            }
        }
        .page--teaser{
            margin-bottom: 1em;
        }
        .page--body{
            max-height: initial;
            min-height: 100px;
            padding-bottom: 3em;
            .rdw-editor-main{
                transition: all 0.3s;
            }
        }

        .edit-mode{
            border-left: 1px solid #fff;
            border-right: 1px solid #fff;
            border-bottom: 1px solid #fff;
            background: #f7f7f7;
            .rdw-editor-main{
                min-height: 200px;
                padding: 0 0.7em;
            }
        }


        .show-more,
        .show-less{
            border: 0px none;
            border-radius: 0px;
            background: transparent none;
            height: 2em;
            min-height: 2rem;
            max-height: 2rem;
            width: 100%;
            position: absolute;
            bottom: -2em;
            left: 50%;
            transform: translateX(-50%);
            text-align: center;
            z-index: 3;
            margin: 0;
            padding: 0;
            @include mq($max, $medium){
                width: 100%;
            }
            &:active{
                background: transparent none;
            }
        
            svg{

                font-size: 1.5rem;
                path{
                    fill: #2d3436;
                }   
            }
            &:after{
                position: absolute;
                top: -1.75em;
                text-align: center;
                left: 50%;
                transform: translateX(-50%);
                opacity: 0;
                font-size: 0.68rem;
                font-weight: bold;
                padding: 0.1em;
                transition: all 0.35s;
                width: 5rem;
            }
            &:hover{
                cursor: pointer;
                &:after{
                    opacity: 1;
                }
            }
            
        }

        .show-more{
            svg{
                margin-top: 1em;
            }
            &:after{
                content: "Show More";
                top: -0.4em;
                background: rgb(235, 235, 235);
            }
        }

        .show-less{
            bottom: -1em;
            &:hover{
                &:after{
                    top: -1.7em;
                    content: "Show Less";
                    background: rgb(235, 235, 235);
                }
            }
        }

        .show-html{
            font-size: 0.7em;
            float: right;
            position: relative;
            right: 0.65em;
            &:hover{
                cursor: pointer;
                svg{
                    path{
                        fill: #777;
                    }
                }
                &:after{
                    position: absolute;
                    left: 0;
                    bottom: 3em;
                    background: #eee;
                    padding: 0.1em;
                    border-radius: 3px;
                    border: 1px solid rgb(199, 199, 199);
                    transition: all 0.35s;
                    content: "HTML";
                    cursor: initial;
                }
                &:after{
                    transition: all 0.35s;
                }
            }         
        }

        .subpages{
            clear: both;
        }
        .number-of-subpages{
            font-size: 0.75em;
            position: relative;
            top: 1em;
            background: #f7f7f7;
            border-radius: 1em;
            text-align: center;
        }

        input.form-control{
            padding: 0 0.25em;
            height: auto;
            font-family: $headerFontStack;
            min-height: auto;
            display: inline-block;
            &.title{
                margin-bottom: 0.5em;
            }
        }

        // .tier1{
        //     .addSubpage{
        //         width: auto;
        //     }
        //     background: $chartBG;
        //     input.form-control{
        //         font-size: $h1Size;
        //     }
        // }
        // .tier2{
        //     background: $tier2;
        //     input.form-control{
        //         font-size: $h2Size;
        //     }
        //     .row .col .container{
        //         padding-left: 0;
        //     }
        // }
        // .tier3{
        //     background: $tier3;
        //     input.form-control{
        //         font-size: $h3Size;
        //     }
        // }
        // .tier4{
        //     background: $tier4;
        //     input.form-control{
        //         font-size: $h4Size;
        //     }
        // }
        // .tier5{
        //     background: $tier5;
        //     input.form-control{
        //         font-size: $h5Size;
        //     }
        // }
        .url{
            text-decoration: underline;
        }
        input.form-control.url{
            display: inline-block;

            font-size: 1em;
            width: auto;
        }
    }
}