.loading-full-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(91, 158, 235);
    z-index: 100;

    .loading-text {
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        font-size: 200%;
        text-align: center;
        animation: fadeIn 5s;
    }

    .done-loading {
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        font-size: 200%;
        text-align: center;
        animation: fadeIn 0.5s;
    }

}

$input-color: #d3d3d3;
$button-color: #f4b9b2;
$info-color: #daedbd;
$comment-highlight-color: #de6b48;
$danger-color: #de6b48;
$icon-color: #808080;

@mixin button($color) {
    background-color: $color;
    border: 1px solid darken($color, 10);

    &:hover {
        background-color: lighten($color, 10);
    }

    &:active {
        background-color: lighten($color, 20);
    }
}

html,
*,
*:before,
*:after {
    box-sizing: border-box;
}

.cookie-consent-container {

    .cookie-consent-content {
        text-align: center;
        flex: 1 0 50% !important;
    }
}

.btn-cookie-consent {
    flex: 1 0 auto;
    top: 2em;
    position: relative;
}

button,
input[type='submit'],
.button {
    display: block;
    width: 100%;
    font-size: $baseFontSize;
    padding: 0.5em;
    @include button($button-color);

    &.star,
    &.update {
        @include button($input-color);
    }

    &.delete {
        @include button($danger-color);
        position: relative;
        background-color: transparent;
        border: 0 none;

        &:before {
            content: "x";
            color: #cecece;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &:hover {
            color: #fff;
            background: red;

            &:before {
                color: #fff;
            }

            &:after {
                content: "delete";
                position: absolute;
                top: -2.5em;
                left: 50%;
                transform: translateX(-50%);
                background: red;
                color: #fff;
                padding: 0.2em 1em 0 1em;
                font-size: 0.7em;
                border-radius: 5px;
                height: 2em;
            }
        }
    }

    &.square {
        padding: 0.55em 0.5em 0.4em 0.5em;
        width: 1.5em;
        height: 1.5em;
    }
}


.jumbotron {
    position: relative;
    padding: 1em;


    .hello-world {
        font-size: 19em;
        line-height: 1em;
        margin-bottom: 1em;

        text-align: center;

        @include mq($max, $small) {
            font-size: 15em;
        }

        @include mq($max, $tiny) {
            font-size: 7em;
        }

        &:hover {
            background: #feccb1;
            background: radial-gradient(ellipse at center, #feccb1 0%, #f17432 50%, #ea5507 51%, #fb955e 67%, #722b62 90%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#feccb1', endColorstr='#722b62', GradientType=1);
            background-repeat: repeat;
            background-position: 0 0;
            background-size: 100% 50px;
            // white-space: nowrap;
            -webkit-text-fill-color: transparent;
            -webkit-background-clip: text;
            -webkit-animation: stripes--explore 2s infinite;
            animation: stripes--explore 3s infinite;
        }

    }

    @keyframes stripes--explore {
        100% {
            background-position: 150px 100%
        }
    }

    .super-size {
        font-size: 12.5em;
        text-align: center;
        margin-bottom: 1em;

        @include mq($max, $tiny) {
            font-size: 10em;
        }

        line-height: 1em;
    }

    .normal-size {
        font-size: 6em;
        line-height: 1em;
    }

    .small-size {
        @include mq($max, $tiny) {
            font-size: 4rem;
        }
    }

    .jumbotron--thanks {
        font-size: 20em;

        @include mq($max, $small) {
            font-size: 8em;
        }

        word-break: break-all;
    }
}


.coming-soon {
    position: relative;

    &:after {
        opacity: 0;
        position: absolute;
        font-size: 0.7em;
        background: #eee;
        padding: 0.1em 0.25em;
        border-radius: 3px;
        border: 1px solid rgb(199, 199, 199);
        top: -2em;
        left: 0;
        text-align: center;
        content: "Coming soon!?";
        cursor: initial;
    }

    &:hover {
        cursor: pointer;

        &:after {
            opacity: 1;
        }

        svg {
            cursor: pointer;
        }

        path {
            fill: rgb(185, 60, 60);
        }

        cursor: inherit;
    }
}

.bread-crumb {

    h1,
    h2,
    h3 {
        display: inline-block;
    }

    .divider {
        display: inline-block;

        &::after {
            content: ">";
            font-size: 2em;

        }
    }
}

.info {
    font-size: 0.8em;
    position: relative;
    z-index: 1;
}

.warning {
    font-size: 0.9rem;
    background: rgb(240, 240, 240);
    font-style: italic;
    padding: 0.25em 1em 0.25em 1.5em;
    display: inline-block;
    border-radius: 0px 2px 5px 0px;
    position: relative;
    left: -1.5em;
    clear: both;
    float: left;
    box-shadow: 0px 1px 2px 0px #b9b9b9;
    margin: 0 0 1em 0;
    position: relative;
    color: red;

    &:before {
        font-style: normal;
        color: rgb(233, 111, 41);
        content: "*";
        margin-right: 0.25em;
        font-weight: bold;
        position: absolute;
        top: 0.25em;
        left: 0.75em;
    }

    .hidden {
        display: none;
    }

    &.warning-contract {
        left: 0;
        top: 2em;
        animation-duration: 1.5s;
    }

    &.aligned {
        left: 0;
    }

    &.mega {
        background: yellow;
        font-size: 1.15rem;
        font-style: normal;
        color: #000;
        float: none;
    }
}


.homepage {
    padding-top: 4em;
}

.page {
    &.page-project {
        padding-top: 0;
    }

    &.logged-in {
        padding-top: 5em;
    }
}

.AddPost,
.AddComment {
    margin-bottom: 1em;
}


.Post {
    border: 1px solid $input-color;
    margin-bottom: 1em;
}

.Post--content {
    padding: 1em;

    h3 {
        margin-top: 0;
    }
}

.Post--meta {
    padding: 0.5em;
    background-color: $info-color;
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
        display: inline-block;
        margin: 1em;
    }

    button {
        display: inline-block;
        width: auto;

        &:not(:last-child) {
            margin-right: 5px;
            margin-bottom: 0;
        }
    }
}

.Comment {
    margin: 1em 0;
}

.Comment--author {
    color: $comment-highlight-color;
    font-weight: bold;

    &:after {
        content: ': ';
    }
}

.Comment--timestamp {
    color: darken($info-color, 40);

    &:before {
        content: ' ';
    }
}

.timer-mini {
    background: #ececec;
    padding: 1em;
    color: #000;
}


.trash {
    position: relative;
    top: 0.5em;

    &:after {
        opacity: 0;
        position: absolute;
        font-size: 0.7em;
        background: #eee;
        padding: 0.1em 0.25em;
        border-radius: 3px;
        border: 1px solid rgb(199, 199, 199);
        top: -2em;
        left: 0;
        text-align: center;
        content: "Delete";
        cursor: initial;
    }

    &:hover {
        cursor: pointer;

        &:after {
            opacity: 1;
        }

        svg {
            cursor: pointer;
        }

        path {
            fill: rgb(185, 60, 60);
        }

        cursor: inherit;
    }
}

.hidden {
    display: none;
}

.hide {
    opacity: 0;
}



.tab-list {
    border-bottom: 1px solid #ccc;
    padding-left: 0;
    margin: 1em 0 0 0;
}

.tab-list-item {
    display: inline-block;
    list-style: none;
    margin-bottom: -1px;
    padding: 0.5rem 0.75rem;

    &:hover {
        cursor: pointer;
    }
}

.tab-list-active {
    background-color: white;
    border: solid #ccc;
    border-width: 1px 1px 0 1px;
}

.tab-content {
    padding: 1em 0;
}


[placeholder]:focus::-webkit-input-placeholder {
    transition: opacity 0.5s 0.5s ease;
    opacity: 0;
}


.user-profile-form {
    .row-img-field {
        margin-bottom: 3em;

        button.delete {
            &:before {
                color: #fff;
            }
        }

        position: relative;

        .img-wrap {
            min-height: 100px;
            min-width: 100px;
        }

        button.delete {
            position: absolute;
            top: 0.25em;
            right: 1.25em;

        }

        img {
            width: 100%;
            height: 100%;
        }
    }
}


.img-placeholder {
    height: 100%;
    width: 100%;
    min-height: 200px;
    min-width: 100px;
    background: #ddd;
    border: 2px solid #fff;
    text-align: center;
    font-size: 2em;
    position: relative;

    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    path {
        fill: #888;
    }
}


.user-photo {
    max-width: 25px;
    max-height: 25px;
}


.section-bg {
    padding: 2em;
    box-shadow: 0px 1px 4px #cfcfcf;

    &:nth-child(even) {
        background: $colorGlobalBG;
    }

    &:nth-child(odd) {
        background: $colorGlobalBG;
    }
}










/*************************************/
/* Mood Board */
/*************************************/

.MoodBoardSearch {
    position: sticky;
    top: 0;
    z-index: 2;
    background: #fff;
}




.quickView {
    overflow-y: scroll;
    overflow-x: none;
    background: rgba(0, 0, 0, 0.67);
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999;

    &:hover {
        img {
            cursor: zoom-out;
        }
    }

    @media only screen and (max-width: 760px) {
        img {
            margin: 25% 5% 15% 25%;
        }
    }

    @media only screen and (min-width: 760px) {
        img {
            margin: 5% 5% 15% 25%;
        }
    }
}


.quickView-trigger {
    position: absolute;
    width: 100%;
    height: 100%;

    &:hover {
        cursor: zoom-in;
    }
}



///// RESULTS
.moodboard-results {
    background: #eee;



    .moodboard-image-row {
        margin-bottom: 5em;
    }

    .moodboard-image-wrapper {
        position: relative;

        .moodboard-image {
            position: relative;

            .quickView-border {
                border: 2px solid transparent;
            }

            &:hover {
                .quickView-border {
                    box-shadow: 3px 1px 10px #919191;
                }
            }



            img {
                display: block;
                margin: 0 auto;
            }



            .moodboard-image__buttons {
                padding: 0.25em 0 0 0;
                width: 100%;
                position: relative;
                z-index: 998;

                .full {
                    color: #fff;
                    background: #aeaeae;
                    border: 0px none;

                    &:hover {
                        background: #fff;
                        color: #aaa;
                    }
                }

                .add {
                    width: 50%;
                    float: right;
                    background: #aeaeae;
                    border: 0px none;

                    &:hover {
                        background: limegreen;
                    }
                }
            }

            .credits {
                background: #eee;
                color: #aaa;
                width: 100%;
                font-size: 0.8em;
                padding: 0.25em 0 0 0;
            }
        }
    }
}





.selectedImages-wrapper {
    border-right: 1px solid #aaa;
    min-height: 100%;
    background: #eee;
}

/// SELECTED IMAGES LIST
.selectedImages {
    position: sticky;
    position: -webkit-sticky;
    top: 6em;
    z-index: 1;
    min-height: 100px;
    overflow-y: scroll;

    .moodboard-image {
        position: relative;

        img {
            display: block;
            width: 100%;
            margin: 0.5em auto 0 auto;
        }

        button {
            display: none;
        }

        button.add {
            border-radius: 50%;
            width: 3em;
            height: 3em;
            position: absolute;
            top: 0;
            right: 0;
        }

        button.remove {
            border-radius: 50%;
            width: 3em;
            height: 3em;
            position: absolute;
            top: 0;
            right: 0;
        }

        &:hover {
            button {
                display: block;
            }
        }
    }
}

.termDef {
    display: none;
}

.term {
    display: inline;
    color: #000;
    background: rgb(250, 250, 250);
    font-weight: 500;

    &:hover {
        .termDef {
            display: block;
            position: fixed;
            top: 50%;
            text-align: center;
            background: #000;
            color: #fff;
            font-size: $baseFontSize;
        }
    }
}




.inline {
    display: inline;
}

.icon-group {
    h1 {
        display: inline-block;
    }
}






.prompt-popup {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    padding: 20%;
    background: #fff;
    color: #000;
    font-size: 2em;
    text-align: center;
    z-index: 9999999;

    &.visible-false {
        display: none;
    }

    &.visible-true {
        display: block;
    }
}



.add-project {
    &.blur-bg {
        filter: blur(0.2em);
        position: fixed;
    }

    .question-section {
        padding: 2em 1em 1em 1em;

        &:nth-child(even) {
            background: #dddddd;
        }

        &:nth-child(odd) {
            background: #f1f1f1;
        }
    }

    form.AddProject {
        margin-bottom: 5em;

    }
}

.paywall {
    &.paying {
        animation: fadeIn 1.5s;
        z-index: 999999;
        background: #000;
        text-align: center;
        display: table;

        .paywall--foreground {
            color: #fff;
            font-size: 1.5em;
            display: table-cell;
            vertical-align: middle;
        }
    }

    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding-top: 5em;

    .paywall--background {
        background: rgba(0, 0, 0, 0.247);
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        position: absolute;
    }

    .paywall--foreground {
        @include mq($min, $tiny) {
            padding: 0em 7em;

        }
    }

    .payment-choice {
        padding: 1.5em;
        background: rgba(0, 0, 0, 0.438);
        min-height: 100%;
        transition: all 0.37s;

        @include mq($min, $tiny) {
            h3 {
                min-height: 4.5em;
            }
        }

        @include mq($min, $small) {
            padding: 2em;

            h3 {
                min-height: 4.5em;
            }
        }

        @include mq($min, $medium) {
            padding: 4em;

            h3 {
                min-height: initial;
            }
        }

        @include mq($min, $desktop) {
            padding: 6em;
        }

        @include mq($min, $large) {
            padding: 8em;
        }

    }

    .payment-choice--or {
        color: $colorTextBody;
        font-size: 3em;
        position: absolute;
        top: 5em;
        left: 50%;
        transform: translateX(-50%);
        z-index: 5;
        transition: all 0.37s;

        @include mq($min, $tiny) {
            font-size: 2.5em;
            top: 5.5em;
        }

        @include mq($min, $small) {
            font-size: 4em;
            top: 1.5em;
        }

        @include mq($min, $medium) {
            font-size: 6em;
            top: 1em;
        }

        @include mq($min, $desktop) {
            font-size: 6em;
            top: 1.5em;
        }

        @include mq($min, $large) {
            font-size: 6em;
            top: 1.5em;
        }

        .card {
            padding: 1rem;
        }
    }
}


.form-check {
    padding: 0;
}

.form-check-label {
    margin: 0 0 0 1em;
}

input[type="checkbox"],
input[type="radio"] {
    margin: 0;
    display: block;
}

.visible-false {
    display: none;
}


html {
    height: 100%;
    background: $colorGlobalBG;
}

body {
    height: 100%;
    margin: 0;
    overflow-x: hidden;
}

#root {
    background: $colorGlobalBG;
    min-height: 100%;
}

.layout-row {
    display: flex;
    flex-direction: row;
    height: 100%;
    min-height: 100vh;
}

.main-panel {
    padding-top: 3.5em;
    position: relative;
    width: 100%;
    // overflow-x: hidden;
}

.main-container {
    padding-top: 1em;

    @include mq($max, $small) {
        max-width: 100%;
    }
}

section.dashboard {
    position: relative;
}

article.dashboard {
    .dashboard-list {

        h1,
        h2 {
            margin-bottom: 0.5em;
            font-size: 2.75em;
            display: inline-block;

            @include mq($max, $small) {
                letter-spacing: normal;
            }
        }

        h1 {
            @include mq($max, $small) {
                font-size: ($h1Size - 0.5em);
            }
        }

        h2 {
            @include mq($max, $large) {
                font-size: 2.4em;
                word-break: break-all;
            }

            @include mq($max, $medium) {
                word-break: normal;
                font-size: 2.2em;
            }
        }

        h3 {
            @include mq($max, $small) {
                letter-spacing: 1px;
            }

        }

        button svg {
            margin-right: 0.5em;
        }
    }
}

section.reference-websites {
    padding-bottom: 2em;
}

.refWeb {
    margin-bottom: 2em;

    &.card {
        button.btn {
            margin: 0 auto 0.5em auto;

            @include mq($max, $small) {
                margin: 1em auto 1em auto;
            }
        }
    }

    .refWeb__item {
        padding-bottom: 1em;
    }

    .refWeb__like,
    .refWeb__dislike {
        white-space: pre-line;
    }
}

.refWeb__edit,
.wireframe--url {
    .invalid-url-off {
        opacity: 0;
    }

    .invalid-url-on {
        font-size: 0.75rem;
        color: red;
        opacity: 1;
    }
}







input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}



.cc-form {
    label {
        display: inline;
    }

    .checkout {
        .StripeElement {
            padding: 1em 0 0 0;
            border-bottom: 1px solid #ddd;
            margin-bottom: 1em;
        }

        .error {
            color: red;
            font-size: 1.2em;
        }

        .waiting {
            width: 100%;
            height: 100%;
            background: rgba(164, 164, 164, 0.8);
            text-align: center;
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            &:before {
                content: "Please wait...";
                font-size: 1.2em;
                position: relative;
                top: 45%;
            }

            .lds-roller {
                display: inline-block;
                position: relative;
                top: 50%;
                width: 6em;
                height: 6em;
                color: #fff;

            }

            .lds-roller div {
                animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
                transform-origin: 32px 32px;
            }

            .lds-roller div:after {
                content: " ";
                display: block;
                position: absolute;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background: rgb(212, 207, 255);
                margin: -3px 0 0 -3px;
            }

            .lds-roller div:nth-child(1) {
                animation-delay: -0.036s;
            }

            .lds-roller div:nth-child(1):after {
                top: 50px;
                left: 50px;
            }

            .lds-roller div:nth-child(2) {
                animation-delay: -0.072s;
            }

            .lds-roller div:nth-child(2):after {
                top: 54px;
                left: 45px;
            }

            .lds-roller div:nth-child(3) {
                animation-delay: -0.108s;
            }

            .lds-roller div:nth-child(3):after {
                top: 57px;
                left: 39px;
            }

            .lds-roller div:nth-child(4) {
                animation-delay: -0.144s;
            }

            .lds-roller div:nth-child(4):after {
                top: 58px;
                left: 32px;
            }

            .lds-roller div:nth-child(5) {
                animation-delay: -0.18s;
            }

            .lds-roller div:nth-child(5):after {
                top: 57px;
                left: 25px;
            }

            .lds-roller div:nth-child(6) {
                animation-delay: -0.216s;
            }

            .lds-roller div:nth-child(6):after {
                top: 54px;
                left: 19px;
            }

            .lds-roller div:nth-child(7) {
                animation-delay: -0.252s;
            }

            .lds-roller div:nth-child(7):after {
                top: 50px;
                left: 14px;
            }

            .lds-roller div:nth-child(8) {
                animation-delay: -0.288s;
            }

            .lds-roller div:nth-child(8):after {
                top: 45px;
                left: 10px;
            }

            @keyframes lds-roller {
                0% {
                    transform: rotate(0deg);
                }

                100% {
                    transform: rotate(360deg);
                }
            }



        }
    }
}


.add-goal,
.add-user-types,
.add-user-story {
    position: relative;
    display: block;
}

.modal-content {
    h5 {
        text-align: center;
    }
}

.save-bar {
    width: 100%;
    animation: fadeIn 0.7s;

    button {
        -webkit-animation: unsavedChanges 5s 1s infinite;
        -moz-animation: unsavedChanges 5s 1s infinite;
        -o-animation: unsavedChanges 5s 1s infinite;
        animation: unsavedChanges 5s 1s infinite;
        height: 3em;
    }

    &.save-bar--sticky,
    &.save-tasks {
        position: sticky;
        bottom: 2em;
        left: 0;
        z-index: 4;
    }
}

.unsaved-changes {
    display: block;
    font-size: 0.75em;
    background: #26ca28;
    color: #fff;
    border-radius: 0 0 0 0.75em;
    box-shadow: 1px 1px 1px rgb(106, 150, 121);
    padding: 0 0.2em;
    top: -0.2em;
    right: -0.2em;
    position: absolute;
    clear: both;
    background: lighten($successGreen, 25%);
}

article.invoice {
    .big-void {
        position: absolute;

        &:after {
            content: "VOID";
            color: red;
            font-size: 10em;
            position: absolute;
            transform: rotate(-45deg);
            opacity: 0.2;
            z-index: 0;
        }
    }
}

article.invoice-payment {
    .prompt {
        display: inline-block;
    }
}

article.project {
    // .prompt{
    //     display: inline-block;
    //     margin: 1em 0 0 0;
    //     &:hover{
    //         cursor: pointer;
    //         path{
    //             fill: red;
    //         }
    //         &:after{
    //             content: "Delete";
    //             display: inline-block;
    //             margin-left: 0.5em;
    //             font-weight: bold;
    //             background: red;
    //             color: #fff;
    //             border-radius: 3px;
    //             font-size: 0.7em;
    //             padding: 0.25em 0.35em;
    //         }
    //     }
    // }

    .no-padding {
        padding: 0;
    }


    .project-type {
        text-transform: capitalize;
    }

    .participant {
        &.updatePulse-true {
            animation: updatePulse 1.5s;
        }
    }

    @keyframes slideUp {
        0% {
            bottom: -50px;
        }

        100% {
            bottom: 0px;
        }
    }

    @keyframes updatePulse {
        0% {
            background: red;
        }

        100% {
            background: none transparent;
        }
    }

    @keyframes unsavedChanges {
        0% {
            background: green;
        }

        50% {
            background: lightgreen;
        }

        100% {
            background: green;
        }
    }
}

.contract {
    .card--contract-summary {
        ul {
            margin: 0;
        }

        .contract--item {
            border-bottom: 1px solid #ccc;
            margin: 0 0 0.5em 0;
            padding: 0;
            list-style-type: none;
            list-style: none;
            font-weight: bold;

            svg,
            span {
                float: right;
                font-weight: normal;
            }

            .draft,
            .review,
            .done,
            .payment {
                color: #fff;
                padding: 0.1em 0.2em;
                line-height: 1.45em;
                text-transform: capitalize;
                float: right;
                transition: all 4s;
                font-weight: normal;
            }

            .payment {
                color: $colorTextBody;
                background: #dfdfdf;
            }

            .draft {
                background: $colorTodo;
            }

            .review {
                background: $colorInProgress;
            }

            .done {
                background: $colorDone;
            }

            .or {
                padding: 0 0.15em;
            }
        }
    }

    .contract-paper-page {
        background: #fff;
        padding-top: 1em;
    }

    .contract-summary {
        background: #fdfdfd;
        padding: 2em;
        border: 1px solid #eee;
        margin-bottom: 2.5em;
    }

    .contract-section {
        margin: 2.5em 0;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .participants-list {
        li {
            margin-bottom: 1.5em;

            p {
                margin: 0;
            }
        }
    }

    .missing-content {
        color: #888;
        font-style: italic;
    }

    .green,
    .red {
        color: #fff;
        padding: 0.1em 0.2em;
    }

    .green {
        background: lighten($colorDone, 15%);
    }

    .red {
        background: lighten($colorTodo, 15%);
    }

    .icon--green {
        margin-left: 0.5em;

        path {
            fill: $colorDone;
        }
    }

    .icon--red {
        margin-left: 0.5em;

        path {
            fill: $colorTodo;
        }
    }

    .pay-via-stripe {
        display: inline-block;
        margin-left: 1em;

        span {
            font-size: 0.75em;
            margin-right: 0.5em;
        }

        svg {
            width: 1em;
        }
    }

    .sigContainer {
        background-color: #fff;
        border: 2px solid #ddd;
        min-height: 200px;
    }

    .sig-box {
        margin-bottom: 3em;
    }

    .sigImage {
        @include mq($max, $medium) {
            background-size: 200px 50px;
            width: 200px;
            height: 50px;
        }

        background-color: white;
    }

    .upload-contract {
        .uploaded-file-info {
            margin-top: 2em;

            .prompt {
                float: right;
            }
        }
    }

    .word-spacing {
        margin: 0 0.23em;
    }

}

.highlight {
    background: yellow;
}

.alt-row {
    padding: 2em;

    @include mq($max, $medium) {
        padding: 2em 0.75em;
    }
}



.add-contract {
    .day-picker-wrapper {
        border: 1px solid #ddd;

        &.hidden {
            z-index: -999;
        }

        background: #fff;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        z-index: 999;
    }

    .DayPicker {
        width: 100%;

        .DayPicker-Month {
            width: 100%;
        }

        .DayPicker-Day {
            border-radius: 0;
        }

        .DayPicker-Day--today {
            border: 1px solid #d0d0d0;
        }
    }

    .DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
        background: #eee;
    }
}


.user-stories {
    .user-story--sort-controls {
        .switch {
            display: inline-block;
            position: relative;
            margin-left: 1em;

            @include mq($max, $tiny) {
                margin: 0.25em;
            }
        }

        .sort-button {
            .btn {
                background: none transparent;
                color: #000;
                border: 0px none;

                &:focus {
                    box-shadow: none;
                }
            }
        }

        span {
            display: inline-block;
            margin: 0;
            position: relative;
            z-index: 1;
            padding: 0.25em 0.25em 0 0.25em;

            svg {
                height: 0.75em;
                width: 0.75em;
                padding: 0.03em;

                &.fa-sort-numeric-down-alt {
                    padding: 0.1em 0.03em 0.1em 0.03em;
                }

                &.fa-sort-numeric-up {
                    padding: 0.1em 0.03em 0.1em 0.03em;
                }

                &.fa-sort-up {
                    padding: 0.2em 0.03em 0.03em 0.03em;
                }

                &.fa-sort-down {
                    padding: 0.03em 0.03em 0.2em 0.03em;
                }
            }
        }

        span.active {
            background: rgb(207, 207, 207);
            transition: all 0.5s;

            svg {
                background: #fff;
            }

            path {
                fill: rgb(143, 143, 143);
            }

            &+.slider:before {
                -webkit-transform: translateX(2em);
                -ms-transform: translateX(2em);
                transform: translateX(2em);
            }
        }

        span.inactive {
            background: rgb(168, 168, 168);
            transition: all 0.5s;

            path {
                fill: #d8d8d8;
            }
        }


        span.slider {
            padding: 0;
            position: absolute;
            height: 0.5em;
            z-index: 1;
            cursor: pointer;

            left: 0;
            right: 0;
            bottom: -0.6em;
            background: none transparent;
            -webkit-transition: .4s;
            transition: .4s;

            &:before {
                position: absolute;
                content: "";
                height: 3px;
                width: 1.5em;
                left: 5px;
                bottom: 4px;
                background-color: rgb(207, 207, 207);
                -webkit-transition: .4s;
                transition: .4s;
            }
        }

    }



    ul {
        margin: 0;
    }

    .user-story {
        list-style-type: none;
        list-style-type: none;
        background: #eee;
        padding: 0.5em;
        position: relative;

        .edit-user-story-status {
            position: absolute;
            background: #fff;
            border: 1px solid #ddd;
            border-radius: 10px;
            box-shadow: 0px 30px 30px #888;
            padding: 1em;
            left: 1.1em;
            bottom: -6em;
            z-index: 2;

            @include mq($min, $small) {
                left: 6em;
                bottom: -4em;
            }

            @include mq($min, $medium) {
                left: 7em;
                bottom: -4em;
            }

            @include mq($min, $large) {
                left: 8em;
                bottom: -2em;
            }

            @include mq($min, $desktop) {
                left: 8em;
                bottom: -3.5em;
            }

            &:before {
                width: 2.3em;
                height: 2em;
                background: #fff;
                transform: rotate(45deg);
                content: "";
                position: absolute;
                left: -0.5em;
                bottom: 32%;

                @include mq($max, $small) {
                    width: 1em;
                    height: 1em;
                    top: -0.5em;
                    left: 47%;
                    bottom: initial;
                }
            }

            button {
                z-index: 2;
                position: relative;
            }
        }

        .user-story--contents {
            padding-right: 1em;
            width: 100%;
            height: auto;
            min-height: 5em;
            margin: auto;
            font-size: 1.125em;

            p {
                margin: auto;
                text-align: center;
            }
        }


        .user-story--meta {
            background: #fff;
            border: 1px solid #ddd;
            display: block;
            margin: 0;
            text-align: center;

            &:hover {
                cursor: default;

                .point--controls {
                    display: block;
                }
            }
        }


        .point--controls {
            position: relative;
            text-align: center;
            font-size: 0.35em;
            display: none;

            @include mq($max, $small) {
                font-size: 0.5em;
                padding: 0.5em;
            }

            &:hover {
                cursor: pointer;
            }

            &.point--up {
                float: right;
                margin: 3em 0.5em 0 0;

                @include mq($max, $small) {
                    margin: 2em 0.5em 0 0;
                }

                svg {
                    path {
                        fill: $icon-color;
                    }
                }

                &:hover {
                    svg {
                        path {
                            fill: #000;
                        }
                    }
                }
            }

            &.point--down {
                float: left;
                margin: 3em 0 0 0.5em;

                @include mq($max, $small) {
                    margin: 2em 0 0 0.5em;
                }

                svg {
                    path {
                        fill: $icon-color;
                    }
                }

                &:hover {
                    svg {
                        path {
                            fill: #000;
                        }
                    }
                }
            }
        }

        .point-value {
            display: inline;
            font-size: 2em;
            text-align: center;
            margin-bottom: 0;
        }

        .points-1 {
            color: #999;
        }

        .points-2 {
            color: #888;
        }

        .points-3 {
            color: #777;
        }

        .points-4 {
            color: #666;
        }

        .points-5 {
            color: #555;
        }

        .points-6 {
            color: #777;
        }

        .points-7 {
            color: #333;
        }

        .points-8 {
            color: #222;
        }

        .points-9 {
            color: #111;
        }

        .points-10 {
            color: #000;
        }

        .user-story--status {
            text-align: center;
            margin-bottom: 0;
            color: #fff;
            padding: 0.5em 0;

            @include mq($max, $small) {
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
}

.status-todo,
.status-to-do {
    background: $colorTodo;
}

.status-in-progress {
    background: $colorInProgress;
}

.status-done {
    background: $colorDone;
}

.status--summary {
    text-align: center;
    font-size: 1.5em;

    @include mq($max, $small) {
        font-size: 1.1em;
    }

    p {
        color: #fff;
    }
}




article.branding {
    .color-row {
        margin-bottom: 5em;
    }

    .color-picker {
        width: 100% !important;

        @include mq($max, $tiny) {
            padding: 0px !important;

            .flexbox-fix {
                padding: 10px;
                margin: 0 !important;
            }
        }
    }

    .color-copy-box {
        width: 10px;
        opacity: 0;
        position: absolute;
        z-index: -1;
    }

    .color-preview {
        width: 100%;
        height: 100%;
        min-height: 150px;
        background: #000;
        cursor: pointer;

    }

    .color-tool-tip {
        z-index: 10000;

        .color-tool-tip--bg {
            background: #424242;
            min-height: 3em;
            border-radius: 10px;
            padding: 1em;
            color: #fff;
        }

        .icon {
            display: inline-block;
            margin: 0 0.2em;
            height: 1em;

            path {
                fill: #fff;
            }
        }

        .color-tool-tip--color {
            display: inline-block;
            color: #fff;
        }
    }
}


.diagram {
    background: #414141;
    border: 2px solid #000;
    overflow: hidden;
    width: 100%;
    position: relative;

    .diagram--icons--agile {
        position: relative;
        left: 9em;

        @include mq($max, $medium) {
            transform: scale(0.8);
            left: 3em;
        }

        @include mq($max, $small) {
            transform: scale(0.6);
            left: -3em;
            top: -5em;
            min-width: 44em;
        }

        @include mq($max, $tiny) {
            transform: scale(0.4);
            left: -9em;
            top: -5em;
            min-width: 38em;
        }
    }

    &.diagram--agile {
        padding: 4em 0 2em 0;
        min-height: 22em;

        @include mq($max, $medium) {
            min-height: 15em;
        }

        @include mq($max, $small) {
            height: 10em;
        }

        .icon--agile-1,
        .icon--agile-2 {
            font-size: 15em;
            position: relative;

            path {
                fill: #4fcdec;
                stroke: black;
                stroke-width: 3;
            }
        }

        .icon--agile-1 {
            transform: scaleX(-1);
            transform: rotate(45deg);
        }

        .icon--agile-2 {
            margin-left: 1em;

            @include mq($max, $small) {
                margin-left: 0.45em;
            }

            @include mq($max, $tiny) {
                margin-left: 0.45em;
            }
        }

        .diagram-steps--agile {
            position: absolute;
            left: 18em;
            top: 4em;

            @include mq($max, $medium) {
                transform: scale(0.8);
                top: 5em;
                left: 15em;
            }

            @include mq($max, $small) {
                transform: scale(0.5);
                top: 3em;
                left: 10em;
            }

            @include mq($max, $tiny) {
                transform: scale(0.5);
                top: 3em;
                left: 7em;
            }
        }

        .agile-steps {
            text-align: center;
            list-style: none;
            counter-reset: my-awesome-counter;

            li {
                counter-increment: my-awesome-counter;
            }

            li::before {
                content: counter(my-awesome-counter) ". ";
                color: #fff;
            }

            .step {
                color: #fff;
                min-width: 6em;
                position: absolute;
                background: rgba(0, 0, 0, 0.3);
                border-radius: 10px;
                font-size: 1.5em;

                @include mq($max, $small) {
                    font-size: 1.3em;
                }

            }

            .step1 {
                top: -1em;
                left: -8em;

                @include mq($max, $tiny) {
                    left: -10em;
                }

            }

            .step2 {
                top: -1em;
                left: 0em;

                @include mq($max, $tiny) {
                    left: -1em;
                }
            }

            .step3 {
                top: 2.25em;
                left: 4em;

                @include mq($max, $tiny) {
                    left: 1.5em;
                    top: 3em;
                }
            }

            .step4 {
                top: 6.5em;
                left: 2em;

                @include mq($max, $tiny) {
                    top: 7em;
                    left: -1em;
                }
            }

            .step5 {
                top: 10em;
                left: -4em;

                @include mq($max, $tiny) {
                    top: 10em;
                    left: -6em;
                }
            }

            .step6 {
                top: 6.5em;
                left: -8.5em;

                @include mq($max, $tiny) {
                    top: 7em;
                    left: -10em;
                }

            }

            .step7 {
                top: 2.25em;
                left: -11.5em;

                @include mq($max, $tiny) {
                    left: -11em;
                    top: 3em;
                }
            }
        }

        .step8 {
            position: absolute;
            top: 2.5em;
            left: 14em;
            min-width: 6em;
            text-align: center;
            background: rgba(0, 0, 0, 0.3);
            color: #fff;
            border-radius: 10px;
            font-size: 1.5em;

            @include mq($max, $small) {
                font-size: 1.3em;
            }

            @include mq($max, $tiny) {
                left: 11em;
                top: 7.5em;
            }
        }

        &:hover {
            .step1 {
                animation: stepPulse 0.5s ease 1;
            }

            .step2 {
                animation: stepPulse 0.5s ease 1;
                animation-delay: 0.5s;
            }

            .step3 {
                animation: stepPulse 0.5s ease 1;
                animation-delay: 1s;
            }

            .step4 {
                animation: stepPulse 0.5s ease 1;
                animation-delay: 1.5s;
            }

            .step5 {
                animation: stepPulse 0.5s ease 1;
                animation-delay: 2s;
            }

            .step6 {
                animation: stepPulse 0.5s ease 1;
                animation-delay: 2.5s;
            }

            .step7 {
                animation: stepPulse 0.5s ease 1;
                animation-delay: 3s;
            }

            .step8 {
                animation: stepPulse 0.5s ease 1;
                animation-delay: 3.5s;
            }
        }
    }



    .diagram--icons--waterfall {
        // position: relative;
        // left: 9em;
        // @include mq($max, $medium){
        //     transform: scale(0.8);
        //     left: 3em;
        // }
        // @include mq($max, $small){
        //     transform: scale(0.6);
        //     left: -3em;
        //     top: -5em;
        //     min-width: 44em;
        // }
        // @include mq($max, $tiny){
        //     transform: scale(0.4);
        //     left: -9em;
        //     top: -5em;
        //     min-width: 38em;
        // }
    }

    &.diagram--waterfall {
        padding: 0em 0 2em 0;
        height: 40em;

        // @include mq($max, $medium){
        //     min-height: 15em;
        // }
        // @include mq($max, $small){
        //     height: 10em;
        // }
        .icon {
            font-size: 3em;
            position: relative;

            path {
                fill: #4fcdec;
                stroke: black;
                stroke-width: 3;
            }
        }

        .diagram-steps--waterfall {}

        .waterfall-steps {
            list-style: none;
            // counter-reset: my-awesome-counter;

            // li {
            //     counter-increment: my-awesome-counter;
            // }

            // li::before {
            //     content: counter(my-awesome-counter) ". ";
            //     color: #fff;                
            // }

            .step {
                color: #fff;
                min-width: 6em;
                position: relative;
                font-size: 1.5em;

                span {
                    min-width: 6em;
                    display: inline-block;
                    color: #fff;
                }

                svg {
                    position: relative;
                    top: 0.75em;
                }
            }

            .step1 {
                left: 0;
            }

            .step2 {
                left: 2em;
            }

            .step3 {
                left: 4em;
            }

            .step4 {
                left: 6em;
            }

            .step5 {
                left: 8em;
            }

            .step6 {
                left: 10em;
            }

            .step7 {
                left: 12em;
            }
        }

        // .step8{
        //     position: absolute;
        //     top: 2.5em;
        //     left: 14em;
        //     min-width: 6em;
        //     text-align: center;
        //     background: rgba(0, 0, 0, 0.3);
        //     color: #fff;
        //     border-radius: 10px;
        //     font-size: 1.5em;
        //     @include mq($max, $small){
        //         font-size: 1.3em;
        //     }
        //     @include mq($max, $tiny){
        //         left: 11em;
        //         top: 7.5em;
        //     }
        // }

        &:hover {
            .step1 {
                animation: waterfallPulse 0.5s ease 1;
            }

            .step2 {
                animation: waterfallPulse 0.5s ease 1;
                animation-delay: 0.5s;
            }

            .step3 {
                animation: waterfallPulse 0.5s ease 1;
                animation-delay: 1s;
            }

            .step4 {
                animation: waterfallPulse 0.5s ease 1;
                animation-delay: 1.5s;
            }

            .step5 {
                animation: waterfallPulse 0.5s ease 1;
                animation-delay: 2s;
            }

            .step6 {
                animation: waterfallPulse 0.5s ease 1;
                animation-delay: 2.5s;
            }

            .step7 {
                animation: waterfallPulse 0.5s ease 1;
                animation-delay: 3s;
            }
        }
    }
}


@keyframes stepPulse {
    0% {
        background: rgba(0, 0, 0, 0.3);
    }

    50% {
        background: red;
    }

    100% {
        background: rgba(0, 0, 0, 0.3);
    }
}

@keyframes waterfallPulse {
    // 0% { path{ fill:#000;} color: #000; }
    // 50% { path{fill: red;} color: red; }
    // 100% { path{fill:#000;} color: #000; }
}


.page--meeting {

    //  Edit Meeting and < GO BACK button wrapper
    .meeting--button-tabs {
        margin: 2em 0 0 0;
    }

    .meeting--tabs--wrapper {
        .tab-content {
            clear: both;
        }
    }

    // Bootstrap's Tabs
    .meeting--tabs.nav-tabs {
        a {
            &.active {
                color: #000;

                &:hover {
                    color: #888;
                }
            }

            &:hover {
                color: #fff;
            }

            color: rgb(148, 148, 148);
        }

    }


    .meeting--list {
        .meeting--section {
            border: 2px solid transparent;
            margin: 0 0.5em 3em 0.5em;
            padding: 0.2em;
            border-radius: 5px;
            position: relative;

            &:hover {
                cursor: pointer;
                border: 2px solid #eee;
            }

            &:after {
                display: block;
                content: "";
                width: 100%;
                height: 2px;
                background: #ddd;
                position: absolute;
                bottom: -1.5em;
            }

            .meeting--purpose {
                max-height: 8em;
                overflow: hidden;
            }
        }

    }

    .meeting {
        .meeting--section {
            margin-bottom: 5em;
            border-bottom: 2px solid #ddd;
            white-space: pre-wrap;
        }
    }

    .meeting--section {
        .inline-list {
            li {
                display: inline-block;
                margin: 0 1em 0.5em 0;
                background: #f3f3f3;
                padding: 0.1em 0.2em;
                border-radius: 0.5em;
            }
        }
    }


    .fancy-checkbox {
        .form-check {
            .form-check {
                width: 120px;
                height: 40px;
                background: #333;
                margin: 20px 60px;
                border-radius: 50px;
                position: relative;

                label {
                    display: block;
                    width: 22px;
                    height: 22px;
                    border-radius: 50%;

                    transition: all .5s ease;
                    cursor: pointer;
                    position: absolute;
                    top: 9px;
                    z-index: 1;
                    left: 12px;
                    background: #ddd;
                }

                &:before {
                    content: '';
                    position: absolute;
                    top: 19px;
                    left: 14px;
                    height: 2px;
                    width: 90px;
                    background: #111;
                }

                input[type=checkbox]:checked+label {
                    left: 84px;
                    background: #26ca28;
                }
            }
        }
    }
}


.table-responsive {
    th {
        &:hover {
            cursor: pointer;
        }
    }

    tbody {
        tr {
            animation: fadeIn 0.8s ease 1;

            .paid {
                background: #26ca28;
                color: #fff;
            }

            .due--late {
                background: red;
                color: #fff;
                width: 100%;
                display: inline-block;
            }

            .due--today,
            .void {
                background: #444;
                color: #fff;
            }

            .due--future {
                background: #ddd;
                color: #000;
            }

            .paid,
            .void,
            .due--late,
            .due--today,
            .due--future {
                padding: 0.2em;
                border-radius: 4px;
            }

            &:hover {
                background: #eee;
                border-color: #ddd;

                td {
                    border-color: #ddd;
                }
            }
        }
    }
}



.chat--container {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 4;
    width: 23em;


    .toggle-chat {
        background: rgb(90, 86, 86);
        text-align: center;
        color: #fff;
        border-radius: 1em 1em 0 0;
        padding: 0.5em 0;

        &:after {
            content: "Hide Chat";
            margin-left: 0.5em;
            font-size: 0.8em;
        }

        &:hover {
            background: rgb(165, 165, 165);
            cursor: pointer;
        }
    }

    &.showChat--true {

        .chat,
        .chat--messages {
            height: auto;
        }

        &.runAnimation {
            animation-name: chatBounceInUp;
            animation-duration: 0.23s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
        }
    }

    &.showChat--false {

        .chat,
        .chat .chat--messages {
            height: 2.5em;
            padding: 0;
        }

        .chat--helper {
            display: none;
        }

        // transform: translate3d(0, 69vh, 0);

        @include mq($max, $tiny) {
            // transform: translate3d(0, 72vh, 0);
        }

        &.runAnimation {
            animation-name: chatBounceOutDown;
            animation-duration: 0.3s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
        }

        .toggle-chat {
            svg {
                position: relative;
                top: 0.05em;
            }

            &:after {
                content: "Show Chat";
            }
        }
    }

    .chat {
        background: rgb(95, 95, 95);
        width: 23em;
        border-radius: 1em 1em 0 0;

        @include mq($max, $tiny) {
            height: 42.5em;
        }

        .chat--inner {}

        .chat--messages {
            padding: 0.5em;
            width: 100%;
            max-height: 50vh;
            height: 50vh;
            overflow-y: scroll;
            box-shadow: inset -3px 5px 15px #000;

            &::-webkit-scrollbar {
                width: 0.75em;
            }

            &::-webkit-scrollbar-track {
                background: #333;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #474747;
                border-radius: 0.5em;
                border: 1px solid rgb(129, 129, 129);
            }

            .chat--message__wrapper {
                display: block;
                width: 100%;
                float: left;
                margin-bottom: 1em;

                .chat--message__timestamp {
                    font-size: 0.8em;
                    color: lighten($colorTextBody, 15%);
                    margin-bottom: 0;
                }

                &.chat--message__self {
                    .chat--message__timestamp {
                        text-align: right;
                    }

                    .card {
                        border-radius: 0.75em 0.75em 0em 0.75em;
                        background: #70aaf7;
                        box-shadow: 2px 2px 6px #464646;
                        color: #fff;
                        width: 80%;
                        float: right;
                    }
                }

                &.chat--message__other {
                    .chat--message__timestamp {
                        text-align: left;
                    }

                    .card {
                        border-radius: 0.75em 0.75em 0.75em 0em;
                        background: #f6feff;
                        box-shadow: 2px 2px 6px #464646;
                        width: 80%;
                        float: left;
                    }
                }



                .chat--message {
                    margin: 1em auto;

                    &:first-of-type() {
                        margin-top: 0;
                    }

                    .chat--message__most-recent {
                        border-radius: 0.75em 0.75em 0em 0.7em;
                    }

                    .chat--message__content {
                        white-space: pre-line;
                    }

                    &:hover {
                        .chat--message__timestamp {
                            opacity: 1;
                            transition: all 0.37s;
                        }
                    }
                }
            }

            .chat--messages__end {
                clear: both;
            }

            .chat--empty {
                height: 10em;
            }
        }

        .chat--helper {
            position: relative;

            .chat--helper__link {
                display: inline-block;
                color: $helperInfo;
                padding: 0.1em;
                float: right;
                margin: 0 1em;

                svg {
                    margin: 0 0.25em;
                }

                &:hover {
                    cursor: pointer;
                }
            }

            .chat--popup {
                position: absolute;
                background: #fff;
                opacity: 0;
                transition: all 0.37s;
                bottom: 0;
                right: 0;
                width: 50%;
                border: 2px solid rgb(80, 80, 80);
                border-radius: 1em 0 0 1em;
                padding: 0.5em;
                transform: translateX(100%);

                @include mq($max, $tiny) {
                    width: 75%;
                }

                &.chat--popup__show {
                    opacity: 1;
                    transition: all 0.37s;
                    transform: translateX(0%);

                    .icon:hover {
                        cursor: pointer;
                    }
                }

                ul.sources {
                    margin: 0;

                    li {
                        border: 1px solid #000;
                        padding: 0.25em;
                        display: inline-block;
                        margin: 0.25em 0.15em;

                        &:hover {
                            cursor: pointer;
                        }
                    }
                }
            }
        }

        .AddChatMessage {
            .message--textarea {
                width: 100%;
                height: 7em;
                resize: none;
                margin: 0.5em 0 0 0;
                border-radius: 0;
                border-top: 0px none;
                border-right: 2px solid #5a5656;
                border-left: 2px solid #5a5656;
                border-bottom: 0px none;
                font-size: 1em;
            }

            .send {
                margin-top: -0.55em;
                border-radius: 0;
                border-top: 0px none;
                border-left: 2px solid #5a5656;
                border-right: 2px solid #5a5656;
                border-bottom: 0px none;
            }
        }
    }
}



// Tasks
.task-board {
    display: flex;
    flex-flow: row;

    &.menuOpen-true {
        max-width: calc(100vw - 366px);
    }

    .task-column {
        flex: 1;
        min-width: 200px;
        margin: 0 0.5em 0 0;

        .column-header {
            text-align: center;
        }

        .task-list {
            clear: both;
        }

        .add-task {
            padding: 0.25em;
            background: #eee;
            border-radius: 0.35em;
            float: right;
            display: block;
            margin-bottom: 1em;

            &:hover {
                background: #f5f5f5;
                cursor: pointer;
            }
        }

        ul.ul-column {
            margin: 0;

            li {
                margin: 0;
                list-style-type: none;
                list-style: none;
                align-items: center;

                .page--title {
                    word-wrap: break-word;
                }
            }


            .show-more,
            .show-less {
                text-align: center;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                width: 99.5%;
                opacity: 0.5;
                transition: opacity 0.2s;

                &:hover {
                    opacity: 1;
                    transition: opacity 0.2s;
                    box-shadow: 2px 2px 0 rgba(102, 123, 139, 0.23);
                    background: darken(#eee, 5%);
                    border-radius: 0 0 3px 3px;
                    cursor: pointer;
                }
            }


            .accordion {
                transition: all 0.2s;

                &.collapse {
                    transition: all 0.2s;
                }
            }

            .public-DraftStyleDefault-block {
                margin: 0 0 1em 0;
            }


            .card-task {
                @include mq($max, $medium) {
                    padding: 0.5em 0.5em;
                }

                position: relative;
                transition: none;

                &:hover,
                &.edit-mode {
                    background: #f4f5f7;
                    box-shadow: 2px 2px 0 rgba(102, 123, 139, 0.23);

                    .icon-click-zone {
                        opacity: 1;

                        &:hover {
                            cursor: pointer;
                            color: #000;
                        }
                    }
                }

                &.more-content {
                    padding: 1em 1em 1.5em 1em;
                }

                &.edit-mode {
                    .rdw-editor-main {
                        .public-DraftStyleDefault-block {
                            margin-top: 0.5em;
                        }

                        min-height: 10em;
                        padding: 0 0.25em;
                        background: #ddd;
                    }
                }

                .icon-click-zone {
                    padding: 0 0.5em;
                    float: right;
                    opacity: 0;
                    z-index: 2;
                    position: relative;
                }

                .task--fields {
                    margin-top: 2em;

                    .title {
                        margin-bottom: 0.5em;
                    }
                }

            }
        }
    }
}


.helper {
    >p {
        display: inline;
    }
}

.loading-spinner {
    position: absolute;
    bottom: 0.25rem;
    right: 0.5rem;

    &::after {
        content: "Loading...";
        font-style: italic;
        font-size: 0.69rem;
        letter-spacing: 0.03rem;
    }
}


.pay-with-stripe {
    margin-top: 3em;
    margin-bottom: 3em;
}