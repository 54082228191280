.typography{
    padding-bottom: 3em;
    .typography__row{
        // background: #f7f7f8;
        // position: relative;
        // display: block;
        // float: left;
    }
    .typography__controls,
    #font-picker-header,
    #font-picker-other{
        // position: sticky;
        // top: 50px;
        float: left;
        // max-width: 365px;
        min-width: 200px;
        width: 100%;
        box-shadow: none;
        // @include mq($max, $small){
        //     display: none;
        // }
        span{
            position: absolute;
            top: -1em;
            right: 0;
            display: block;
            color: rgb(184, 179, 255);
            &:hover{
                cursor: pointer;
            }
        }
        div[id^=font-picker]{
            width: 100%;
            button{
                margin-bottom: 0;
            }
        }
    }
    .typography__view{
        float: right;
    }
    .form-group{
        margin-bottom: 2em;
        position: relative;
    }
    .form-label{
        opacity: 1;
        transform: translateY(0);
    }
}