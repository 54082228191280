/*============================================================================
  #Breakpoint and Grid Variables
==============================================================================*/
$viewportIncrement: 1px;

$tiny: 575px;
$small: 767px;
$medium: 991px;
$desktop: 1123px;
$large: 1199px;

$postTiny: $tiny + $viewportIncrement;
$postSmall: $small + $viewportIncrement;
$preMedium: $medium - $viewportIncrement;
$preLarge: $large - $viewportIncrement;

/*================ The following are dependencies of csswizardry grid ================*/
$breakpoints: (
  'tiny' '(max-width: #{$tiny})',
  'small' '(max-width: #{$small})',
  'medium' '(min-width: #{$postSmall}) and (max-width: #{$medium})',
  'medium-down' '(max-width: #{$medium})',
  'large' '(min-width: #{$large})'
);
$breakpoint-has-widths: ('tiny', 'small', 'medium', 'desktop', 'large');
$breakpoint-has-widths: ('small', 'medium', 'medium-down', 'large');
$breakpoint-has-push:  ('medium', 'medium-down', 'large');
$breakpoint-has-pull:  ('medium', 'medium-down', 'large');


/*============================================================================
  #General Variables
==============================================================================*/

// Global Background Color
$colorGlobalBG: #f7f7f8;

$colorPrimary: #70aaf7;
$colorSecondary: #638fbd;

$colorTodo: #e20b0b;
$colorInProgress: #ffa500;
$colorDone: #008000;

$darkCard: #e6e6e6;
$darkCardHover: #d4d4d4;

// Button colors
$colorBtnPrimary: $colorPrimary;
$colorBtnPrimaryHover: darken($colorBtnPrimary, 10%);
$colorBtnPrimaryActive: darken($colorBtnPrimaryHover, 10%);
$colorBtnPrimaryText: #fff;

$colorBtnSecondary: $colorSecondary;
$colorBtnSecondaryHover: darken($colorBtnSecondary, 10%);
$colorBtnSecondaryActive: darken($colorBtnSecondaryHover, 10%);
$colorBtnSecondaryText: #fff;

// Text link colors
$colorLink: $colorPrimary;
$colorLinkHover: lighten($colorPrimary, 15%);

// Text colors
$colorTextBody: #727272;

// Border colors
$colorBorder: #eee;

// Nav and dropdown link background
$colorNav: #f2f2f2;
$colorNavText: #dbdbdb;
$colorNavBG: #424242;

// Site Footer
$colorFooterBg: $colorSecondary;
$colorFooterText: #000;


// Logo max width
$logoMaxWidth: 450px;

// Helper colors
$helperInfo: #70aaf7;
$disabledGrey: #f6f6f6;
$disabledBorder: darken($disabledGrey, 25%);
$errorRed: #d02e2e;
$errorRedBg: #fff6f6;
$successGreen: #56ad6a;
$successGreenBg: #ecfef0;
$label: #929292;

// Sizing variables
$siteWidth: 1180px;
$gutter: 10px;
$gridGutter: 10px; // can be a %, but nested grids will have smaller margins because of it
$radius: 3px;

// Z-index
$zindexNavDropdowns: 5;
$zindexDrawer: 10;

/*================ Typography ================*/
 
$headerFontStack:  'Muli',  Arial, sans-serif;
$headerFontWeight: normal;
$headerFontColor: #6b6b6b;


$bodyFontStack: 'Roboto', Arial, sans-serif;
$baseFontSize: 14px; // Henceforth known as 1em

$buttonFontStack: 'Muli', Arial, sans-serif;

$h1Size: 2.8em; 
$h2Size: 2.4em;
$h3Size: 1.8em;
$h4Size: 1.3em;
$h5Size: 1.2em;
$h6Size: 1.1em;


/*============================================================================
  #Sass Mixins
==============================================================================*/
.clear{
    clear: both;
}
.clearfix {
  &:after {
    content: '';
    display: table;
    clear: both; }
  *zoom: 1;
}

@mixin clearfix() {
  &:after {
    content: '';
    display: table;
    clear: both; }
  *zoom: 1;
}



/*============================================================================
  Dependency-free breakpoint mixin
    - Based on http://blog.grayghostvisuals.com/sass/sass-media-query-mixin/
    - Usage docs: http://shopify.github.io/Timber/#sass-mixins
==============================================================================*/
$min: min-width;
$max: max-width;
@mixin mq ($constraint_, $viewport1_, $viewport2_:null) {
 $constraint: $constraint_; $viewport1: $viewport1_; $viewport2: $viewport2_;
  @if type-of($constraint_) == number {
    $viewport1 : $constraint_; $viewport2 : $viewport1_; $constraint : null;
  }
  @if $constraint == $min {
    @media screen and ($min: $viewport1) {
      @content;
    }
  } @else if $constraint == $max {
    @media screen and ($max: $viewport1) {
      @content;
    }
  } @else {
    @media screen and ($min: $viewport1) and ($max: $viewport2) {
      @content;
    }
  }
}

/*============================================================================
  #Normalize
==============================================================================*/
*, input, :before, :after {
  box-sizing: border-box;
}

html, body {
  padding: 0;
  margin: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
  display: block;
}

audio, canvas, progress, video {
  display: inline-block;
  vertical-align: baseline;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}


/*============================================================================
  #Basic Styles
==============================================================================*/
html {
    background-color: #fff;
}

body {
  background-color: none-transparent;
}

[tabindex='-1']:focus {
  outline: none;
}

.wrapper {
  @include clearfix();
  max-width: $siteWidth;
  margin: 0 auto;
  padding: 0 calc($gutter / 2);

  @include mq($min, $small) {
    padding: 0 $gutter;
  }
}

.main-content {
  display: block;
  margin-top: $gutter;
  padding-bottom: $gutter * 2;
}

/*============================================================================
  #Helper Classes
==============================================================================*/
.hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px; width: 1px;
  margin: -1px; padding: 0; border: 0;
}

/*============================================================================
  #Typography
==============================================================================*/
body,
input,
textarea,
button,
select {
  font-size: $baseFontSize;
  line-height: 1.6;
  font-family: $bodyFontStack;
  color: $colorTextBody;
  font-weight: 500; 
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
}

input,
textarea,
select{
  color: #000;
}

h1, h2, h3, h4, h5, h6 {
    display: block;
    font-family: $headerFontStack;
    font-weight: $headerFontWeight;
    color: $headerFontColor;
    margin: 0 0 0.5em;
    line-height: 1.4;

  a {
    text-decoration: none;
    font-weight: inherit;
  }
}

/*================ Use em() Sass function to declare font-size ================*/
h1 {
  font-size: $h1Size;
  text-shadow: 0px 1px 1px #ffffff;
  @include mq($max, $small) {
      text-shadow: none;
      font-size: ($h1Size - 0.25em);
  }
}


h2 {
  font-size: $h2Size;
  text-shadow: 0px 1px 1px #ffffff;
  @include mq($max, $small) {
    text-shadow: none;
  }
}

h3 {
  font-size: $h3Size;
}

h4 {
  font-size: $h4Size;
}

h5 {
  font-size: $h5Size;
}


h6 {
  font-size: $h6Size;
}


.h1 { @extend h1; }
.h2 { @extend h2; }
.h3 { @extend h3; }
.h4 { @extend h4; }
.h5 { @extend h5; }
.h6 { @extend h6; }

p {
  margin: 0 0 calc($gutter / 2) 0;

  img {
    margin: 0;
  }
}

em {
  font-style: italic;
}

b, strong, .bold {
  font-weight: bold;
}

small {
  font-size: 0.9em;
}

sup, sub {
  position: relative;
  font-size: 60%;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}

sub {
  bottom: -0.5em;
}

/*================ Blockquotes ================*/
blockquote {
  font-size: 1.125em;
  line-height: 1.45;
  font-style: italic;
  margin: 0 0 $gutter;
  padding: calc($gutter / 2) $gutter;
  border-left: 1px solid $colorBorder;

  p {
    margin-bottom: 0;

    & + cite {
      margin-top: calc($gutter / 2);
    }
  }

  cite {
    display: block;
    font-size: 0.75em;

    &:before {
      content: '\2014 \0020';
    }
  }
}

/*================ Code ================*/
code, pre {
  background-color: #faf7f5;
  font-family: Consolas,monospace;
  font-size: 1em;
  border: 0 none;
  padding: 0 2px;
  color: #51ab62;
}

pre {
  overflow: auto;
  padding: calc($gutter / 2);
  margin: 0 0 $gutter;
}

/*================ Horizontal Rules ================*/
hr {
  clear: both;
  border-top: solid $colorBorder;
  border-width: 1px 0 0;
  margin: $gutter 0;
  height: 0;

  &.hr--small {
    margin: calc($gutter / 2) 0;
  }

  &.hr--clear {
    border-top-color: transparent;
  }
}

/*============================================================================
  #Lists
==============================================================================*/
ul, ol {
  margin: 0 0 calc($gutter / 2) 20px;
  padding: 0;
}

ol { list-style: decimal; }
ul ul, ul ol,
ol ol, ol ul { margin: 4px 0 5px 20px; }
li { margin-bottom: 0.25em; }

ul.square { list-style: square outside; }
ul.disc { list-style: disc outside; }
ol.alpha { list-style: lower-alpha outside; }

.no-bullets {
  list-style: none outside;
  margin-left: 0;
}

.inline-list {
  margin-left: 0;

  li {
    display: inline-block;
    margin-bottom: 0;
  }
}

/*============================================================================
  #Tables
==============================================================================*/
table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

table.full {
  width: 100%;
  margin-bottom: 1em;
}

.table-wrap {
  max-width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

th {
  font-weight: bold;
}

th, td {
  text-align: left;
  padding: calc($gutter / 2);
  border: 1px solid $colorBorder;
}

/*============================================================================
  Responsive tables, defined with .table--responsive on table element.
  Only defined for IE9+
==============================================================================*/
.table--responsive {
  @include mq($max, $small) {
    thead {
      display: none;
    }

    tr {
      display: block;
    }

    // IE9 table layout fixes
    tr,
    td {
      float: left;
      clear: both;
      width: 100%;
    }

    th,
    td {
      display: block;
      text-align: right;
      padding: 15px;
    }

    td:before {
      content: attr(data-label);
      float: left;
      text-align: center;
      font-size: 12px;
      padding-right: 10px;
    }
  }
}

@include mq($max, $small) {
  .table--small-hide {
    display: none !important;
  }

  .table__section + .table__section {
    position: relative;
    margin-top: 10px;
    padding-top: 15px;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 15px;
      right: 15px;
      border-bottom: 1px solid $colorBorder;
    }
  }
}

/*============================================================================
  #Images and Iframes
==============================================================================*/
img {
  border: 0 none;
}

svg:not(:root) {
  overflow: hidden;
}

img,
iframe {
  max-width: 100%;
}

.video-wrapper {
  position: relative;
  overflow: hidden;
  max-width: 100%;
  padding-bottom: 56.25%;
  height: 0;
  height: auto;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}


/*================ Prevent zoom on touch devices in active inputs ================*/
@include mq($max, $medium) {
  input,
  textarea {
    font-size: 16px;
  }
}

input,
textarea,
button,
select {
  padding: 0;
  margin: 0;
}

button {
  background: none;
  border: none;
  cursor: pointer;
}

button,
input,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type="image"] {
  padding-left: 0;
  padding-right: 0;
}

fieldset {
  border: 1px solid $colorBorder;
  padding: calc($gutter / 2);
}

legend {
  border: 0;
  padding: 0;
}

input,
textarea,
select {
  border: 1px solid $colorBorder;
  max-width: 100%;
  padding: 8px 10px;
  border-radius: $radius;

  &:focus {
    border: 1px solid darken($colorBorder, 10%);
  }

  &[disabled],
  &.disabled {
    cursor: default;
    background-color: $disabledGrey;
    border-color: $disabledBorder;
  }

  &.input-full {
    width: 100%;
  }
}

textarea {
  min-height: 100px;
}

/*================ Input element overrides ================*/
input[type="checkbox"],
input[type="radio"] {
  display: inline;
  margin: 0 8px 0 0;
  padding: 0;
  width: auto;
}

input[type="checkbox"] {
  -webkit-appearance: checkbox;
  -moz-appearance: checkbox;
}

input[type="radio"] {
  -webkit-appearance: radio;
  -moz-appearance: radio;
}

input[type="image"] {
  padding-left: 0;
  padding-right: 0;
}

select {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  text-overflow: '';
  cursor: pointer;
}

optgroup {
  font-weight: bold;
}

// Force option color (affects IE only)
option {
  color: #000;
  background-color: #fff;
}

select::-ms-expand {
  display: none;
}

/*================ Form labels ================*/
.hidden-label {
  position: absolute;
  height: 0;
  width: 0;
  margin-bottom: 0;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);

  // No placeholders, so force show labels
  .ie9 &,
  .lt-ie9 & {
    position: static;
    height: auto;
    width: auto;
    margin-bottom: 2px;
    overflow: visible;
    clip: initial;
  }
}

label[for] {
  cursor: pointer;
}


/*================ Error styles ================*/
input,
select,
textarea {
  &.error {
    border-color: $errorRed;
    background-color: $errorRedBg;
    color: $errorRed;
  }
}

label.error {
  color: $errorRed;
}


// Flexbox grid
.grid--flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.flex-items-center {
  align-items: center;
}
.flex-items-end {
  align-items: flex-end;
}
.flex-row-reverse {
  flex-direction: row-reverse;
}

.no-margin{
    margin: 0;
}

.no-padding{
    padding: 0;
}

.center{
    text-align: center;
}

.pad1{
    padding: 1em;
}

.mar1{
    margin: 1em;
}

.margin-left-1{
    margin-left: 1em;
}

.margin-right-1{
    margin-right: 1em;
}

.margin-bottom-1{
    margin-bottom: 1em;
}

.margin-top-1{
    margin-top: 1em;
}


.float-left {
    float: left;
}

.float-right{
    float: right;
}

.inline-block{
    display: inline-block;
}

.strikethrough{
    text-decoration: line-through;
}

.clickable{
    &:hover{
        cursor: pointer;
    }
}