input,
button {
    display: block;
    width: 100%;

    &:not(:last-child) {
        margin-bottom: 5px;
    }

    &:focus {
        outline: none;
    }
}

input[type='text'],
input[type='email'],
input[type='password'] {
    border: 1px solid $input-color;
    font-size: $baseFontSize;
    padding: 0.5em;
    margin-bottom: 0.5em;
}

.form-group {
    margin-bottom: 1em;

    &.input {
        margin-bottom: -1em;
    }

    &.select {}

    &.radio {
        &.radio-role {
            margin-top: 2em;
        }
    }

    &.checkbox {
        .form-check-label {
            position: relative;
            top: 2.65em;
            left: 0.85em;
            width: auto;
            font-size: 0.65em;
            font-weight: bold;
            letter-spacing: 0.5px;
            text-transform: uppercase;
            color: $label;
            opacity: 1;
            transform: translateY(-25px);
        }
    }
}

input.form-control {
    padding: 1.5em 0.5em 0.5em 0.5em;
    min-height: 2.75em;

    &.repeater {
        margin: 0 0 0.5em 0;
        padding-top: 0.375em;
    }

    &::placeholder {
        color: lighten($label, 15%);
    }

    &.official-name {
        margin-bottom: 1em;
    }
}

select.form-control {
    height: 38.5px;
    padding: 0.5em 0 0 0.5em;
}

textarea.form-control {
    padding: 0.65em 0.45em;
}

.form-label {
    position: relative;
    top: 2.65em;
    left: 0.85em;
    width: auto;
    font-size: 0.65em;
    font-weight: bold;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: $label;
    opacity: 0;
    transform: translateY(-25px);
    transition: all 150ms ease-in;

    &.field-active {
        transform: translateY(0px);
        opacity: 1;
    }

    &.select-label {
        opacity: 1;
        transform: translateY(0px);
    }

    &.check-label {
        opacity: 1;
        transform: translateY(0px);
        left: 2em;
    }

    &.radio-label {
        opacity: 1;
        transform: translateY(0px);
        left: 0em;
        top: 0.5em;
    }
}


.form-check {
    line-height: 1em;

    input.form-check-input {
        margin-bottom: 0em;
    }

    .form-check-label {
        margin: 0 0 0.75em 1.5em;
    }
}



.additional-fees {
    .form-label {
        position: absolute;
    }

    .fee-amount-label {
        top: 0.5em;
        left: 0em;
        color: $label;
        opacity: 1;
        transform: translateY(0);
        float: left;
        font-size: 1em;
        margin: 0;
    }

    .fee-amount {
        // width: calc(100% - 1em);
        padding: 0.375rem 0.4rem;
        margin: 0;
        min-height: auto;
    }

    .feeFrequency-label {
        opacity: 1;
        left: -1em;
        top: 3.5em;
        text-transform: lowercase;
        font-size: 0.75em;
        font-weight: normal;

        @include mq($max, $small) {
            left: -0.6em;
        }
    }
}

input[type="file"] {
    margin: 1em 0;
    padding: 1em 0;
}



.AddComment {
    display: flex;

    input {
        margin: 0;
        padding: 0.5em;
    }

    input[type="text"] {
        flex: 3;
    }

    input[type="submit"] {
        flex: 1;
    }
}

.editable {
    text-decoration: underline;
    border: 1px solid #eee;
    border-radius: 0.25rem;
    line-height: 1.5;
    margin: 0 0.2em;

    &:hover {
        cursor: pointer;
    }
}

.read-only {
    border: 1px solid #eee;
    border-radius: 0.25rem;
    line-height: 1.5;
    margin: 0 0.2em;
}


section.user-stories {
    .user-story--contents {
        .select-userType {
            margin: 0 0.2em;
            font-size: 1em;
            font-weight: bold;
            width: auto;
            height: 1.5em;
            padding: 0em;
            background: #fff;
            display: inline;
            font-size: 1em;
            text-decoration: underline;

            &:focus {
                -webkit-box-shadow: none;
                box-shadow: none;
            }

            &:hover {
                cursor: pointer;
            }
        }

        input.form-control {
            font-size: 1em;
            width: auto;
            margin: auto 0.2em;
            padding: 0;
            min-height: auto;
            height: auto;
            display: inline-block;
        }
    }

    .trash {
        margin-right: 1em;
    }
}


/*
    ADD USER STORY
*/
form.add-user-story {

    // margin-bottom: 5em;
    .user-story-delete {
        margin: 1em 1em 0 0;
    }



    input,
    select {
        border: 0px none;
        border-bottom: 1px solid #ced4da;
        border-radius: 0;
        float: right;
    }

    .select-userType.form-control,
    .input-goal.form-control,
    .input-reason.form-control {
        margin-left: 0;
        padding-left: 0;
        position: relative;
        width: 90%;
    }

    .userStory-text.form-label {
        float: left;
        display: block;
        width: 5%;
        min-width: 6em;
        position: relative;
        top: 50%;
        transform: translateY(-70%);
        z-index: 1;
    }

    .story-points {
        background: #808080;
        color: #fff;
        width: 30px;
        height: 30px;
        clear: both;
        text-align: center;
        font-size: 1.5em;
    }


    .form-control {
        display: inline-block;
        margin: auto 0.5em;
    }


    .btn-group {
        width: 100%;

        .btn {
            height: 2.5em;
            color: #000;
            background-color: transparent;

            &.selected {
                color: #fff;

                &:after {
                    content: "";
                    height: 2px;
                    width: 75%;
                    background: #000;
                    color: #fff;
                    position: absolute;
                    bottom: -0.5em;
                    left: 50%;
                    transform: translateX(-50%);
                }

                &.btn-danger {
                    background: red;
                }

                &.btn-warning {
                    background: orange;
                }

                &.btn-success {
                    background: rgb(0, 197, 0);
                }
            }
        }
    }

    .status-buttons {
        button {
            @include mq($max, $small) {
                font-size: 0.87em;
            }
        }
    }
}


/*
    ADD Form
*/
.add-form {
    padding-top: 1em;

    input.form-control {
        margin-top: 0;
        padding: 0.5rem;


        &.range-points {
            padding: 0 0 0 0.35rem;

            &:hover {
                cursor: grab;
                outline: none !important;

                &:active {
                    cursor: grabbing;
                    outline: none !important;
                }
            }

            &:focus {
                border: 0px none;
                outline: none;
            }
        }
    }


    .points-slider {
        datalist {
            display: flex;
            justify-content: space-between;
            width: 100%;
            font-size: 1rem;

            option {
                &:before {
                    content: "";
                    height: 0.5rem;
                    width: 0.5rem;
                    border-top: 1px solid #000;
                    position: absolute;
                    bottom: 1.5rem;
                }
            }
        }
    }


    .form-label {
        opacity: 1;
        top: 0;
        left: 0;
        transform: translateY(0px);
    }
}



.user-profile-form {
    input.form-control {
        padding: 0.5em;
        margin-bottom: 1em;
    }

    input[type=file] {
        margin: 0 0 1em 0;
        padding: 0;
    }

    .form-label {
        margin: 1em 0;
        opacity: 1;
    }
}


.AddDesigns,
.addWireframes {
    .row {
        margin-bottom: 1em;
    }

    .form-label {
        display: none;
    }
}

.overflow-url {}


.form-add-lead {
    margin-bottom: 2em;

    .save-lead {
        margin-top: 1em;
    }
}


form.add-invoice {
    padding-bottom: 5em;

    .change-due-date {
        input {
            margin: 0 0.5em;
            height: auto;
        }
    }

    .invoice-number {
        margin-top: 2em;

        label {
            opacity: 1;
        }
    }

    #number {
        padding: 0.375rem 0.75rem;
        min-height: auto;
    }

    .due-date {
        .updatePulse {
            animation: updatePulse 1.5s;
        }
    }

    // Invoice Item (card)
    .invoice-item {
        .form-control {
            display: inline-block;
        }

        label {
            position: relative;
            top: 1.5em;
            left: 1em;
            font-size: 0.65em;
            font-weight: bold;
            letter-spacing: 0.5px;
            text-transform: uppercase;
            color: #929292;
            opacity: 1;
            transition: all 150ms ease-in;
            margin: 0;
        }

        .trash {
            float: right;
            margin-bottom: 1em;
        }

        input {
            max-width: 50%;
        }

        textarea {
            margin-bottom: 1em;
        }

        .amount {
            margin: 0.5em 0 0 0;
            text-align: right;
        }
    }

    .summary {
        background: #eee;
        margin: 2em 0 5em 0;

        .summary__inner {
            padding: 1em;
        }
    }
}

.late-fee-amount,
.late-fee-type,
.late-fee-freq {
    display: inline-block;
    height: 2.75em !important;
    margin: 0 !important;
    font-size: 1rem !important;
}